import { IsValid } from './IsValid';

export const GetProducts = async()=>{
  let email = await IsValid();
  let res = await fetch(`${process.env.REACT_APP_API_URL}api/vendor/products?email=${email}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then(data=>data);
  if(res.status===200){
    const isJson = res.headers.get('content-type')?.includes('application/json');
    const data = isJson ? await res.json() : null;
    if(data.length>0)return data;  
  }
  return [];
}