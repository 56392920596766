import { Box, Stack, Typography } from "@mui/material";
import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
    cx, cy,
    midAngle,
    innerRadius, outerRadius,
    percent,
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const CustomPieChart=(props)=>{
    const [colors, setColors] = React.useState([]);
    let { groups, chartTitle } = props;

    React.useEffect(()=>{
        let COLORS = ['#00C49F', '#0088FE', '#FFBB28', '#FF8042', '#FE007F', '#351c75'];
        let lenDiff = COLORS.length - groups.length;
        setColors(COLORS);
        if(lenDiff>0){
            let i = COLORS.length - lenDiff;
            COLORS = COLORS.splice(0, i);
            setColors(COLORS);
        }
        
    }, [chartTitle, groups.length, colors.length]);

    return (
        <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
        }}
        >
            <PieChart width={200} height={200}>
                <Pie
                data={groups}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                >
                    {groups.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                </Pie>
                <Tooltip />
            </PieChart>
            <Stack gap={2}>
                <Typography variant="h6">{chartTitle}</Typography>
                <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap' }}>
                    {colors.map((color, i) => (
                    <Stack key={color} alignItems="center" spacing={1}>
                        <Box sx={{ width: 20, height: 20, background: color }} />
                        <Typography variant="body2" sx={{ opacity: 0.7 }}>
                            {groups[i]?.name}
                        </Typography>
                    </Stack>
                    ))}
                </Box>
            </Stack>
        </Box>
    )
}
export default CustomPieChart;