const Categories = [ "Appetizer", "Soups & Stew", "Rice", "Swallow", "Meat", "Side", "Snacks", "Small Chops" ];
const Cuisines = [ "West African", "Central African", "East African", "North African", "Southern African" ];

const OperatingCountries = [
    {"name":"USA", "currencySign": "$", "currencyCode": "USD", "phone":
        {
            "areaCode": "+1", "length": "10", "format": "5134567890",
        },
        "payment": [
        {
            type: "zelle",
            label: "Phone Number or Email"
        },
        {
            type: "venmo",
            label: "@"
        },
        {
            type: "cashapp",
            label: "$cashtag"
        },
        // {
        //     type: "card",
        //     label: "Card",
        //     field: "checkbox",
        //     spanText: "Stripe payment for debit and credit card payments"
        // },
    ]},
    // {
    //     "name":"Nigeria", "currencySign": "₦", "currencyCode": "NGN", "phone":
    //     {
    //         "areaCode": "+234", "length": "10", "format": "8012345678",
    //     },
    //     "payment": [
    //         {
    //             type: "bank account",
    //             label: "bank name, account name, account number"
    //         },
    //         // {
    //         //     type: "card",
    //         //     label: "Card",
    //         //     field: "checkbox",
    //         //     spanText: "Stripe payment for debit and credit card payments"
    //         // },
    //     ]},
];
const faqs = [
    {
        "question": "Setting Up",
        "answer": [
            "To get started, set up the following: ",
            "\u2022 Account Information: This includes your phone number, business name, business address, logo(if you have one). Please ensure the information provided is accurate. ",
            "\u2022 Settings: This includes: ",
            "\u00A0 \u00A0 \u00A0 \u2023 cuisine. Select from the options provided or contact us if your cuisine is not listed.",
            "\u00A0 \u00A0 \u00A0 \u2023 order type.",
            "\u00A0 \u00A0 \u00A0 \u2023 accepted payment methods (contact us to add a payment type not listed in the options).",
            "\u00A0 \u00A0 \u00A0 \u2023 food serving types and the maximum number of people each serving serves.",
            "\u00A0 \u00A0 \u00A0 \u2023 availability settings: any days or dates you won't be accepting orders.",
            "\u2022 Menu",
        ]
    },
    {
        "question": "I am not seeing my business listed on Africaterer",
        "answer": [
            "Ensure all required information have been provided and are accurate. Allow up to 3 business days after your setup for your account to be verified.",
        ]
    },
];
const serviceTypes = ["Catering"]; //, "Shop"

const config= {
    Categories,
    OperatingCountries,
    faqs,
    Cuisines,
    serviceTypes
}
export default config;