import { Box, Button, ImageList, Grid,
  Typography, ImageListItem, Divider
} from "@mui/material";
import React from "react";
import Item from "../../shared/Item";
import config from "../../config";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ProductModal from "../../components/ProductModal";
import { GetProducts } from "../../functions/GetProducts";

export default function Products() {
  localStorage.setItem("HeaderText", "Products");
  const [isLoading, setIsLoading] = React.useState(true);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [currency, setCurrency] = React.useState("$");
  const [products, setProducts] = React.useState([]);
  const [selected, setSelected] = React.useState({});
  const [action, setAction] = React.useState("Add");

  React.useEffect(()=>{
    const getData=async()=>{
      // get all products
      setProducts(await GetProducts());
      // get currency
      const bizAddy = localStorage.getItem("businessAddress");
      if(bizAddy){
        const country = config.OperatingCountries.filter(o=>o.name===(JSON.parse(bizAddy).country));
        setCurrency(country[0].currencySign);
      }
      setIsLoading(false);
    }
    getData();
  }, []);

  if(isLoading){
    return (
      <div style={{padding: 40, textAlign: "center"}}>
        <Typography variant="h2">Loading....</Typography>
      </div>
    );
  }

  if(products.length<=0){
    return (
      <div style={{padding: 40, textAlign: "center"}}>
        <Typography variant="h2">You have no products listed.</Typography>
      </div>
    )
  }

  const openModal=()=>setModalOpen(true);

  const getSelectedProduct=(id)=>{
    const selectedProduct = products.filter(product=>product._id === id);
    setSelected(selectedProduct[0]);
  }

  const editProduct=(id)=>{
    getSelectedProduct(id);
    setAction("Edit");
    openModal();
  }

  const deleteProduct=(id)=>{
    getSelectedProduct(id);
    setAction("Delete");
    openModal();
  }

  if(modalOpen){
    return <ProductModal open={modalOpen} prod={selected} action={action}/>
  }

  return (
    <Box>
      <Button style={{float: "right"}} variant="outlined" color="success" onClick={openModal}>ADD NEW PRODUCT</Button>
      <br />
        <div>
          <Grid container spacing={2}>
          {products.map(product =>(
            <Grid item key={product._id} md={3} xs={12}>
              <Item style={{textAlign: "left"}}>
                <Typography style={{textAlign:"center"}}>{product.name?.toUpperCase()}</Typography>
                <div className="product-list">
                  {product.description&&<Typography><span>Description: </span>{product.description}</Typography>}
                  <span>Sizes: </span>{product.sizes?.map((size, i) =>(
                    <Typography key={i}>{"\u2022"}{" "}{size.size}: {currency}{size.price}</Typography>
                  ))}
                  <Typography><span>Tags: </span>{product.tags&&product.tags.length>0?product.tags?.join(", "):"None"}</Typography>
                </div>
                {product.imageUrls?
                <div>
                  <ImageList cols={3} sx={{ height: 100 }} rowHeight={50}>
                    {product.imageUrls.map((item, i) => (
                      <ImageListItem key={i}>
                        <img
                          srcSet={`${item.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          src={`${item.url}?w=164&h=164&fit=crop&auto=format`}
                          alt={`food pic - ${item.id}`}
                          loading="lazy"
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </div>:<Typography style={{textAlign: "center", padding:4, color: "#00004b"}}>NO IMAGES</Typography>
                }
                <Divider />
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <div>
                    <Typography>In Stock: {product.available}</Typography>
                  </div>
                  <div>
                    <EditIcon color="success" style={{cursor:"pointer"}} onClick={()=>editProduct(product._id)} />
                    <DeleteIcon color="error" style={{cursor:"pointer"}} onClick={()=>deleteProduct(product._id)} />
                  </div>
                </div>
              </Item>
            </Grid>
          ))}
          </Grid>
        </div>
      {/* } */}
    </Box>
  );
}