import React from "react";
import Item from '../shared/Item';
import { TextField, FormGroup, FormControlLabel, Checkbox, Button, Stack, Divider, FormControl } from "@mui/material";
import { UpdateSetting } from "../functions/UpdateSettings";
import config from "../config";
import { HandleStripePayment } from "../functions/HandleStripePayment";

const PaymentSetting=({val, addy, serviceType})=>{
    const [paymentSetting, setPaymentSetting] = React.useState(val||[]);
    const [paymentTypes, setPaymentTypes] = React.useState([]);

    const setPaymentConfig=()=>{
        let setting = {};
        setting.settingName = "paymentType";
        setting.settingValue = paymentSetting;
        UpdateSetting("add", setting);
    }

    const handlePaymentSetting=(e)=>{
        const {name, value, checked, type } = e.target;
        if(type==="text")setPaymentSetting((prev) => {return {...prev, [name]: value }});
        if(type==="checkbox")setPaymentSetting((prev) => {return {...prev, [name]: checked }});
        if(name.toLowerCase()==="card" && checked)hasStripePament();
    }

    React.useEffect(()=>{
        if(serviceType&&serviceType.toLocaleLowerCase().includes("shop")){
            const pmt = [{
                type: "card",
                label: "Card",
                field: "checkbox",
                spanText: "Stripe payment for debit and credit card payments"
            }];
            setPaymentTypes(pmt);
        }
        else{
            const pmt = config.OperatingCountries.filter(n=>n.name === addy.country);
            if(pmt[0].payment.length>0)setPaymentTypes(pmt[0].payment);
        }
    }, [addy, serviceType]);

    const hasStripePament=async()=>{
        await HandleStripePayment();
    }

    return (
        <>
        <Stack>
            <Item>Select accepted payment methods<br/><br/>
            <Divider color="error"/><Divider color="default"/><br/>
                <FormGroup>
                    {serviceType&&serviceType.toLocaleLowerCase().includes("catering")&&<FormControlLabel value="cash" control={<Checkbox />} onChange={(e)=>setPaymentSetting((prev) => {return {...prev, "cash": e.target.checked }})} label="Cash" checked={paymentSetting["cash"]||false} />}
                    {paymentTypes.map((p, i)=>(
                        <FormControl key={i} fullWidth>
                            {(!p.field)?<TextField variant="standard" name={p.type} defaultValue={paymentSetting[p.type]} label={p.label} helperText={p.type} onChange={handlePaymentSetting} />
                            :
                            <>
                            <FormControlLabel value={p.type} name={p.type} control={<Checkbox />} onChange={handlePaymentSetting} label={p.label} checked={paymentSetting[p.type]||false} />
                            <span style={{ textAlign: "left", fontSize: 9, fontWeight: "bolder", color: "#134f5c" }}>{p.spanText}</span>
                            </>
                            }
                        </FormControl>
                    ))}
                </FormGroup>
                <br/>
                <Button variant="outlined" color="secondary" onClick={setPaymentConfig}>Save Payment Type</Button>
            </Item>
        </Stack>
        </>
    )
}

export default PaymentSetting;