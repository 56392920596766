import React from 'react';
import CustomPieChart from './CustomPieChart';

export default function OrderStatusPieChart({orders}) {
  const [statusGroups, setStatusGroups] = React.useState([]);

  React.useEffect(() => {
    let pending = 0, completed = 0, cancelled = 0;
    orders.forEach((order) => {
      if (order.status === "pending")return pending++;
      if (order.status === "completed")return completed++;
      cancelled++;
    });
    setStatusGroups([
      { name: 'Pending', value: pending },
      { name: 'Completed', value: completed },
      { name: 'Cancelled', value: cancelled },
    ]);
  }, [orders]);

  return (
      <CustomPieChart groups={statusGroups} chartTitle={"Order Status"}/>
    );
}
