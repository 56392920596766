import { IsValid } from './IsValid';

export const UpdatePopupSettings = async(setting)=>{
  let email = await IsValid();
  await fetch(`${process.env.REACT_APP_API_URL}api/vendor/popupsetting?email=${email}`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(setting)
  })
  .then(()=>{
    window.location.reload();
  });
  return;
}