import { IsValid } from './IsValid';

export const UpdateProduct = async(mtd, data)=>{
  let email = await IsValid();
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/vendor/product?email=${email}`, {
    method: mtd,
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  if(response.ok){
    window.location.reload(true);
  }
  else{
    console.log(response.statusText);
  }
}