import * as React from 'react';
import { Divider, Dialog, Typography, IconButton, Toolbar, Grid, Box, DialogContent,
    AppBar, Button, TextField, Select, MenuItem, InputLabel, FormControl
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import Item from '../../shared/Item';
import { GetOrders } from '../../functions/GetOrders';
import { Edit, Search } from '@mui/icons-material';
import { UpdateOrder } from '../../functions/UpdateOrder';
import { GetCurrency } from '../../functions/GetCurrency';

export default function Orders()
{
    const [pageSize, setPageSize] = React.useState(5);
    localStorage.setItem("HeaderText", "Orders");
    const [open, setOpen] = React.useState(false);
    const [editing, setEditing] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [selectedData, setSelectedData] = React.useState({});
    const [currency, setCurrency] = React.useState("");
    // const serviceType = localStorage.getItem("serviceType");

    const columns = [
        { field: '_id', headerName: 'ORDER ID', headerAlign: 'center', filterable: false, hide: true },
        { field: 'customerName', headerName: 'CUSTOMER', width: 150, headerAlign: 'center' },
        { 
            field: 'status', headerName: 'STATUS', headerAlign: 'center', type: 'singleSelect',
            valueOptions: ['pending', 'completed', 'cancelled']
        },
        { field: 'orderType', headerName: 'ORDER TYPE', width: 150, headerAlign: 'center', type: 'singleSelect',
        valueOptions: ['pickup', 'delivery'] },
        { field: 'orderDate', headerName: 'ORDER DATE', type: 'date', width: 150, headerAlign: 'center', valueFormatter: params => new Date(params?.value).toLocaleDateString()},
        { field: 'orderFulfillDate', headerName: 'FULFILL DATE', type: 'date', width: 220, headerAlign: 'center', valueFormatter: params => new Date(params?.value).toLocaleString([], {dateStyle: "long", timeStyle: 'short'}) },
        { field: 'paymentType', headerName: 'PAYMENT', width: 150, headerAlign: 'center', type: 'singleSelect',
        valueOptions: ['cash', 'zelle', 'venmo', 'cashapp', 'card'] },
        {
            field: 'catererNote', headerName: 'MY NOTES', headerAlign: 'center', width: 150,
        },
        { field: 'total', headerName: `Total (${currency})`, type: 'number', headerAlign: 'center' },
        // { field: (serviceType&&serviceType.toLowerCase().includes("shop")?'shopTotal':'total'), headerName: `Total (${currency})`, type: 'number', headerAlign: 'center' },
        {
            field: 'save', headerName: '', filterable: false, width: 10, renderCell:(params)=>(
                <Edit style={{ cursor: "pointer" }} onClick={()=>showDetails(params, true)}/>
            ),
        },
        {
            field: 'view', headerName: '', filterable: false, width: 10, renderCell:(params)=>(
                <Search style={{ cursor: "pointer" }} onClick={()=>showDetails(params)}/>
            ),
        }
    ];

    React.useState(()=>{
        const getOrders=async()=>{
            let res = await GetOrders();
            setData(res);
            setCurrency(GetCurrency());
        }
        getOrders();
    }, []);

    const showDetails = (e, edit=false) => {
        let sctData = data.filter(r=>r._id === e.row._id);
        if(sctData.length>0)setSelectedData(sctData[0]);
        setOpen(true);
        setEditing(edit);
    };

    const closeDetails=()=>{
        setOpen(false);
    }

    const updateSelected=(e)=>{
        const { value, name } = e.target;
        let arr = {...selectedData};
        arr[name]=value;
        setSelectedData(arr);
    }

    const changeOrderStatus=async()=>{
        await UpdateOrder(selectedData);
    }

    return (
        <>
        <p className="center">Pending Orders: &nbsp;&nbsp;
            <span style={{ color: 'red' }}>{data.filter(a=>a.status.toLowerCase() === "pending").length}</span>
        </p>
        <Divider/>
        <div style={{ height: 400, width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        rows={data}
                        columns={columns}
                        columnHeaders
                        disableSelectionOnClick
                        disableDensitySelector
                        disableColumnSelector
                        pageSize={pageSize}
                        rowsPerPageOptions={[5, 10, 25]}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        disableColumnMenu
                        components={{ Toolbar: data.length>0 && GridToolbar }}
                        density="compact"
                        getRowId={(row) =>row._id}
                    />
                </div>
            </div>
        </div>
        <div>
        <p style={{ textAlign: 'center'}}>
            <span style={{ color: 'green', fontWeight: 'bold', fontSize:20 }}>Completed Orders: {data.filter(a=>a.status.toLowerCase()==="completed").length}</span>
        </p>
        </div>
        <Dialog open={open}>
            <AppBar sx={{ position: 'relative', backgroundColor: "maroon" }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={closeDetails} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Order ID: {selectedData._id}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Item>
                                {selectedData.customerName}<br/> <span style={{ fontSize: 10}}>Phone: {selectedData.customerPhone}</span><br/>
                                {selectedData.customerAddress&&<span style={{ fontSize: 10}}>Address: {selectedData.customerAddress}</span>}
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Item style={{ fontSize: 11, textAlign: "right"}}>
                                <p>Order Date: {new Date(selectedData.orderDate).toDateString()}</p>
                                <p>Order Fulfillment Date: {new Date(selectedData.orderFulfillDate).toLocaleString([], {dateStyle: "long", timeStyle: "short"})}</p>
                                <p>Order Type: {selectedData.orderType}</p>
                                <p>Payment Type: {selectedData.paymentType}</p>
                                {/* <p>Price: {currency}{selectedData.shopTotal||selectedData.total}</p> */}
                                <p>Price: {currency}{selectedData.total}</p>
                                {selectedData.customerNote&&<p>Comments: {selectedData.customerNote}</p>}
                                {editing?
                                <>
                                <FormControl variant="standard" size="small">
                                    <InputLabel>Order Status</InputLabel>
                                    <Select value={selectedData.status} name="status" label="status" onChange={updateSelected}>
                                        <MenuItem value="completed">Completed</MenuItem>
                                        <MenuItem value="pending">Pending</MenuItem>
                                        <MenuItem value="cancelled">Cancelled</MenuItem>
                                    </Select>
                                </FormControl><br /><br />
                                <TextField name="catererNote" placeholder='any notes?' size="small" onChange={updateSelected}/>
                                </>
                                :<><p>{"Order Status:"} {selectedData.status}</p><p>{"Note: "}{selectedData.catererNote || "N/A"}</p></>}
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Item>
                                Order Details <Divider />{selectedData.orderItems&&selectedData.orderItems.map((oi, i)=>(
                                    <p key={i}>{oi.food||oi.name} ( {oi.quantity} )<br/>
                                    {oi.serving&&<span style={{fontSize: 10}}>serving: {oi?.serving}</span>}
                                    {oi.subs&&oi.subs.length>0&&<span style={{fontSize: 10}}><br />with: {oi.subs.map(s=>s.item).join()}</span>}
                                    </p>
                                ))}
                            </Item>
                        </Grid>
                    </Grid>
                    <br />
                    {editing&&<Button variant="outlined" color="secondary" onClick={changeOrderStatus} fullWidth>UPDATE ORDER</Button>}
                </Box>
            </DialogContent>
        </Dialog>
        </>     
    );
}