import * as React from 'react';
import { AccordionSummary, AccordionDetails, TableContainer, TableBody, Table,
    Typography, Accordion, Paper, TableRow, TableHead, TableCell, Divider, TextField,
    Button, FormControlLabel, Checkbox,
    Avatar
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { UpdateMenu } from '../functions/UpdateMenu';
import { saveFileToStorage } from '../functions/AddToStorage';
import MenuSettingsModal from "./MenuSettingsModal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const CustomMenu=(props)=> {
    const [modalOpen, setModalOpen] = React.useState(false);
    const [food, setFood] = React.useState({});

    let { menu, currency, availableServings } = props;

    const handleDeleteFood = (f)=>{
        UpdateMenu("delete", f);
    }

    const editMenu=(f)=>{
        const foodObject = f;
        if(food.food)foodObject.food = food.food;
        if(food.description!==undefined)foodObject.description = food.description;
        if(food.available!==undefined)foodObject.available = food.available;
        if(food.imageUrl)foodObject.imageUrl = food.imageUrl;
        foodObject.availableServings = availableServings;
        setFood(foodObject);
        setModalOpen(true);
    }

    if(menu.length===0){
        return (
        <div>
            <Typography>Menu Not Found</Typography>
        </div>
        )
    }

    const handleFoodImageChange=async(e, f)=>{
        e.preventDefault();
        let selectedFile = e.target.files[0];
        await saveFileToStorage(selectedFile, f, "food");
    }

    return (
    <div>
        <MenuSettingsModal modalOpen={modalOpen} food={food} method="edit"/>
        {menu.sort((a, b) => b["available"] - a["available"]).map(f=>(
        <Accordion key={f.food}>
            <AccordionSummary style={{backgroundColor: f.available?null:"#333", opacity:0.8, color: f.available?null:"#eee"}}
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography style={{color: "#100084", fontWeight: "bold"}}>{f.food}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{display: "flex",}}>
                    <Avatar variant="square" src={f.imageUrl} alt="Image" sx={{ width: "20%", height:"100%" }} />
                    <Button sx={{height:"10%", alignSelf: "center", m:2}} size="small" color="error" component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
                        <input hidden accept="image/*" type="file" onChange={(e)=>handleFoodImageChange(e, f)}/>Change Image
                    </Button>
                </div><br />
                <TextField label="Name" defaultValue={f.food||""} size="small" fullWidth onChange={(e)=>setFood((prev) => {return {...prev, "food": e.target.value}})} /><br/>
                <br /><TextField defaultValue={f.description||""} label="Description" size="small" fullWidth onChange={(e)=>setFood((prev) => {return {...prev, "description": e.target.value}})}  />
                <br /><FormControlLabel control={<Checkbox defaultChecked={f.available} onChange={(e)=>setFood((prev) => {return {...prev, "available": e.target.checked}})} />} label="Available" />
                <br /><br />
                <Divider />
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: "bolder" }}>Serving</TableCell>
                                <TableCell align="right" style={{ fontWeight: "bolder" }}>Price</TableCell>
                                <TableCell align="right" style={{ fontWeight: "bolder" }}>Substitutes</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {f.servings&&f.servings.map(s => (
                            <TableRow
                                key={s.serving}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">{s.serving}</TableCell>
                                <TableCell align="right">{currency}{s.price}</TableCell>
                                <TableCell align="right">{s.substitutes&&s.substitutes.length>0?s.substitutes.map(s=>(
                                    <p key={s.item}>w/ {s.item} = +{currency}{s.addPrice}</p>
                                )):"None"}</TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <Button size="small" variant="contained" color="error" onClick={()=>handleDeleteFood(f)}>DELETE</Button>{"  "}
                <Button size="small" variant="contained" onClick={()=>editMenu(f)} style={{ backgroundColor: "#000033" }}>EDIT</Button>
            </AccordionDetails>
        </Accordion>
        ))}
    </div>
    );
}
export default CustomMenu;