import { storage } from "../firebase";
import { deleteObject, ref } from "firebase/storage";

export const deleteFileFromStorage = (downloadUrl)=>{
    const fileRef = ref(storage, downloadUrl);
    deleteObject(fileRef).then(() => {
        return "204";
    }).catch((error) => {
        console.log(error, "Error deleting file from storage");
        return null;
    });
}