import React from "react";
import Item from '../shared/Item';
import FormControlLabel from "@mui/material/FormControlLabel";
import { Stack, Button, Divider, Checkbox, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import { UpdateSetting } from "../functions/UpdateSettings";

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const AvailabilitySetting=({workDays, holidays})=>{
    const [daysOff, setDaysOff] = React.useState(workDays||[]);
    const [datesOff, setDatesOff] = React.useState(holidays === undefined ? [{"DateFrom":null, "DateTo":null}]: holidays);
    const [daysOffChanged, setDaysOffChanged] = React.useState(false);
    const [datesOffChanged, setDatesOffChanged] = React.useState(false);

    const handleDateRangeAdd=()=>{
        setDatesOff([...datesOff, { "DateFrom": null, "DateTo": null }]);
    }

    const handleDateRangeRemove=(index)=>{
        setDatesOffChanged(true);
        let dates = [...datesOff];
        dates.splice(index, 1);
        setDatesOff(dates);
    }

    const handleDateRangeChange=(e, t, i)=>{
        setDatesOffChanged(true);
        let dates = [...datesOff];
        dates[i][t] = new Date(e).toDateString();
        setDatesOff(dates);
    }

    const saveAvailability=()=>{
        if(datesOffChanged)saveDateRange();
        if(daysOffChanged)saveOffDays();
    }

    const saveDateRange=()=>{
        let setting = {};
        setting.settingName = "holidays";
        setting.settingValue = datesOff;
        UpdateSetting("add", setting);
    }

    const addDayOff=(e)=>{
        setDaysOffChanged(true);
        let offDays = [...daysOff];
        const {value, checked} = e.target;
        if(checked){
            setDaysOff([...offDays, value]);
        }
        else{
            let i = offDays.findIndex(a=>a === value);
            if(i !== -1)offDays.splice(i, 1);
            setDaysOff(offDays);
        }
    }

    const saveOffDays=()=>{
        let setting = {};
        setting.settingName = "workDays";
        setting.settingValue = daysOff;
        UpdateSetting("add", setting);
    }

    return (
        <>
        <Stack 
            direction={{ sm: "column", md: "row" }}
            spacing={2} justifyContent="space-around">
            <Item>Set the days and/or dates you'll be closed for business<br/><br/>
                <Divider color="error"/><Divider color="default"/><br/>
                {days.map((d, i)=>(
                    <FormControlLabel key={i} value={d} control={<Checkbox />} onChange={addDayOff} label={d} checked={daysOff.includes(d)} />
                ))}
                {datesOff.map((d, i)=>(
                <div key={i}>
                    <div style={{ justifyContent: "space-between" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                            <DatePicker autoOk label="Date From:" disableToolbar clearable margin="dense" minDate={d.DateFrom} value={d.DateFrom} onChange={(e) => handleDateRangeChange(e, "DateFrom", i)} />
                        </MuiPickersUtilsProvider>{" "}
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                            <DatePicker autoOk label="Date To:" disableToolbar clearable disablePast margin="dense" minDate={d.DateFrom} value={d.DateTo} onChange={(e) => handleDateRangeChange(e, "DateTo", i)} />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div>
                        {datesOff.length < 5 &&
                        <IconButton title="Add another date range" onClick={handleDateRangeAdd}><AddCircleOutlineIcon fontSize="large" /></IconButton>}
                        {datesOff.length > 1 &&
                        <IconButton title="Remove" onClick={()=>handleDateRangeRemove(i)}><RemoveCircleOutlineIcon fontSize="large" /></IconButton>}
                    </div>
                </div>
                ))}
                <br/><br/>
                <Button variant="outlined" color="secondary" onClick={saveAvailability}>Save</Button>
            </Item>
        </Stack>
        </>
    )
}

export default AvailabilitySetting;