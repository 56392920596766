import { IsValid } from './IsValid';

export const HandleStripePayment = async()=>{
  let email = await IsValid();
  fetch(`${process.env.REACT_APP_API_URL}api/payment/subaccount?email=${email}`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
  })
  .then(async response=>{
    if(response.ok){
      let res = await response.json();
      if(res.url){
        window.open(res.url);
      }
    }
    else{
      return Promise.reject(response.statusText);
    }
  })
  .catch(error=>console.log("Error: ", error));
}