import { storage } from "../firebase";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { UpdateCaterer } from "./UpdateCaterer";
import { UpdateMenu } from "./UpdateMenu";

export const saveFileToStorage=async(file, f, path)=>{
    if (!file) return;
    let url = "fakepath";
    let uploadSnapshot = null;

    let fileType = file.type.split("/")[1];
    let fileName = `${path}/${f._id}.${fileType}`;

    const storageRef = ref(storage, `files/${fileName}`);
    await uploadBytes(storageRef, file).then((snapshot) => {
        console.log("File Uploaded");
        uploadSnapshot = snapshot;
    });

    await getDownloadURL(uploadSnapshot.ref).then((downloadURL) => {
        f.imageUrl = downloadURL;
        if(path === "food")UpdateMenu("edit", f);
        if(path === "caterer")UpdateCaterer("PATCH", f);
        url = downloadURL;
    }).catch((error) => {
        console.log("Error getting downloadUrl", error);
    });

    return url;
}