import React from "react";
import Item from '../shared/Item';
import { Button, Stack, 
    Divider, RadioGroup, Radio, FormControlLabel, Typography 
} from "@mui/material";
import { UpdateSetting } from "../functions/UpdateSettings";
import config from '../config.js';

const GeneralSetting=({val})=>{
    const [genSettings, setGenSettings] = React.useState(val||[]);
    const [cuisine, setCuisine]= React.useState("");

    const handleSettingChange=(e)=>{
        const {value, name } = e.target;
        let gSettings = [...genSettings];
        let st = {};
        st.name = name;
        st.value = value;
        let i = gSettings.findIndex(a=>a.name === name);
        if(i !== -1)gSettings.splice(i, 1);
        gSettings.push(st);
        setGenSettings(gSettings);
    }

    const saveGeneral=()=>{
        let setting = {};
        setting.settingName = "general";
        setting.settingValue = genSettings;
        UpdateSetting("add", setting);
    }

    React.useEffect(()=>{
        let cuisineIndex = genSettings.findIndex(a=>a.name === "cuisine");
        if(cuisineIndex!==-1)setCuisine(genSettings[cuisineIndex].value);
    },[genSettings]);


    return (
        <>
        <Stack>
            <Item>General Settings<br/><br/>
                <Divider color="error"/><Divider color="default"/><br/>
                <Item>
                    <Typography style={{ fontWeight: "bold" }} align = "left">Type of Cuisine</Typography>
                    <RadioGroup value={cuisine} name="cuisine">
                        {config.Cuisines.map((c, i)=>(
                        <FormControlLabel key={i} value={c} control={<Radio />} label={c} onChange={handleSettingChange} />
                        ))}
                    </RadioGroup>
                </Item>
                <br/>
                <Button variant="outlined" color="secondary" onClick={saveGeneral}>Save</Button>
            </Item>
        </Stack>
        </>
    )
}

export default GeneralSetting;