import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './screens/caterer/Main';
import Home from './screens/caterer/Home';
import React from 'react';
import Login from './screens/Login';

function App() {
  const user = localStorage.getItem('user');

  return (
    <div className="wrapper">
      <BrowserRouter>
        <Routes>
          {!user&&<Route path="/" element={<Main/>}/>}
          {user&&<Route path="/" element={<Home/>}/>}
          <Route path="/login" element={<Login/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;