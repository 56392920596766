import * as React from 'react';
import { AttachMoney, ShoppingCart } from '@mui/icons-material';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import OrderStatusPieChart from '../../components/charts/pie/OrderStatusPieChart';
import BestSellerPieChart from '../../components/charts/pie/BestSellerPieChart';
import { GetOrders } from '../../functions/GetOrders';
import { GetCurrency } from '../../functions/GetCurrency';

const Dashboard=()=> {
    localStorage.setItem("HeaderText", "Dashboard");
    const [data, setData] = React.useState([]);
    const [completedOrders, setCompletedOrders] = React.useState([]);
    const [currency, setCurrency] = React.useState("");
    React.useEffect(() =>{
        const getOrders=async()=>{
            let res = await GetOrders();
            setCurrency(GetCurrency());
            setData(res);
            let compltd = res.filter(a=>a.status==="completed");
            setCompletedOrders(compltd);
        }
        getOrders();
    }, []);

    return (
    <Box sx={{
        display: { xs: 'flex', md: 'grid' },
        gridTemplateColumns: 'repeat(3,1fr)',
        gridAutoRows: 'minmax(100px, auto)',
        gap: 3,
        textAlign: 'center',
        flexDirection: 'column',
      }}
    >
        <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h4">Total Orders</Typography>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            >
                <ShoppingCart sx={{ height: 100, width: 100, opacity: 0.3, mr: 1 }} />
                <Typography variant="h4">{data.length}</Typography>
            </Box>
        </Paper>
        <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h4">Total Earnings</Typography>
            <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            >
                <AttachMoney sx={{ height: 100, width: 100, opacity: 0.3, mr: 1 }} />
                <Typography variant="h4">{currency}{completedOrders.reduce((a,v) =>  a = a + v.total , 0 ).toFixed(2)}</Typography>
            </Box>
        </Paper>
        <Paper elevation={3} sx={{ p: 2, gridColumn: 3, gridRow: '1/4' }}>
            <Box>
                <Typography>Recent Orders</Typography>
                <List>
                    {data.sort((a, b)=>new Date(b.orderDate) - new Date(a.orderDate)).slice(0, 5).map((order, i) => (
                    <Box key={order?._id}>
                        <ListItem>
                            <ListItemText
                            primary={order?.customerName}
                            secondary={`Order Created: ${new Date(order?.orderDate).toLocaleDateString()} | Status: ${order?.status}`}
                            />
                        </ListItem>
                        {i !== 3 && <Divider variant="inset" />}
                    </Box>
                    ))}
                </List>
            </Box>
        </Paper>
        <Paper elevation={3} sx={{ p: 2, gridColumn: '1/3' }}>
            <OrderStatusPieChart orders={data} />
        </Paper>
        <Paper elevation={3} sx={{ p: 2, gridColumn: '1/3' }}>
            <BestSellerPieChart orders={data} />
        </Paper>
    </Box>   
    );
}
export default Dashboard;