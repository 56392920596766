import { getAuth } from "firebase/auth";
import { ClearCache } from '../functions/ClearCache';
import { UpdateCaterer } from "../functions/UpdateCaterer";

export const signOut=()=>{
    getAuth().signOut().then(()=>{
        ClearCache();
    });
}

export const deleteCaterer=async(cat)=>{
    let uid = getAuth().currentUser.uid;
    cat.uid = uid;
    await UpdateCaterer("DELETE", cat);
    ClearCache();
}