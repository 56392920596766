import React, {useState} from "react";
import Item from '../shared/Item';
import { FormGroup, TextField, Button, Stack, Divider, Box } from "@mui/material";
import { UpdateSetting } from "../functions/UpdateSettings";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";

const ServingSetting=({val})=>{
    const [servingSettings, setServingSettings] = useState(val===undefined?
        [
            {"serving": "small", "size":0},
            {"serving": "large", "size":0},
            {"serving": "xtra large", "size":0},
            {"serving": "party", "size":0}
        ]:val);
    const [serving, setServing] = useState({});
    const [oldServing, setOldServing] = useState({});

    const saveServing=()=>{
        const servingToUpdate = oldServing.serving?oldServing:serving;
        if(!servingToUpdate.serving || !servingToUpdate.size || servingToUpdate.size===0)return;
        const servings = servingSettings.filter(setting=>setting.serving !== servingToUpdate.serving);
        servings.push(servingToUpdate);
        setServingSettings(servings);
        setServing({});
        setOldServing({});
    }

    const removeServing=(srvn)=>{
        const servings = servingSettings.filter(s=>s.serving !== srvn);
        setServingSettings(servings);
    }

    const saveServingsSettings=()=>{
        const validServings = servingSettings.filter(s=>s.size > 0);
        let setting = {};
        setting.settingName = "servings";
        setting.settingValue = validServings;
        UpdateSetting("add", setting);
    }

    return (
        <>
        <Stack>
            <Item>Set your food servings and the maximum number of people each one serves<br/><br/>
                <Divider color="error"/><Divider color="default"/><br/>
                <FormGroup>
                    {servingSettings.map((s)=>(
                        <Box key={s.serving}>
                            <TextField variant="outlined" defaultValue={s.serving} margin="dense" label={"serving type"} disabled size="small"
                                onChange={(e)=>setOldServing((prev) => {return {...prev, "serving": e.target.value, "size": s.size}})} />
                            <TextField variant="outlined" defaultValue={s.size} margin="dense" label={"serves how many"}  size="small" type="number"
                                onChange={(e)=>setOldServing((prev) => {return {...prev, "size": parseInt(e.target.value), "serving": s.serving}})}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />
                            <SaveIcon style={{marginTop: 9, cursor:"pointer"}} onClick={saveServing} fontSize="large"/>
                            <DeleteIcon style={{marginTop: 9, cursor:"pointer"}} onClick={()=>removeServing(s.serving)} fontSize="large"/>
                        </Box>
                    ))}
                    <br/>
                    <span style={{fontWeight: "bolder"}}>Add New Serving</span>
                    <Box>
                        <TextField variant="outlined" value={serving.serving||""} margin="dense" label={"serving"} size="small"
                            onChange={(e)=>setServing((prev) => {return {...prev, "serving": e.target.value}})} />
                        <TextField variant="outlined" value={serving.size||0} margin="dense" label={"size"} size="small" type="number" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            onChange={(e)=>setServing((prev) => {return {...prev, "size": parseInt(e.target.value)}})} />
                        <AddCircleIcon style={{marginTop: 9, cursor:"pointer"}} fontSize="large" onClick={saveServing} />
                    </Box>
                </FormGroup>
                <br/>
                <Button variant="outlined" color="secondary" onClick={saveServingsSettings}>Save Changes</Button>
            </Item>
        </Stack>
        </>
    )
}

export default ServingSetting;