import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import {Grid} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Link from '@material-ui/core/Link';
import {Typography, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import useStyles from '../hooks/UseStyles.js';
import { resetPassword, signInWithEmail } from '../auth/SignIn.js';

export default function Login() {
  const classes = useStyles();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = e => {
    e.preventDefault();
    signInWithEmail(email, password);
  }
  const [modalOpen, setModalOpen] = useState(false);
  const [passwordResetEmail, setPasswordResetEmail] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [resetSuccessModalOpen, setResetSuccessModalOpen] = useState(false);

  const handlePasswordReset = async(e)=>{
    e.preventDefault();
    setSuccessMessage();
    setErrorMessage();
    if(!passwordResetEmail){
      setErrorMessage("Please enter a valid email address.");
    }
    const res = await resetPassword(passwordResetEmail);
    if(res === "successful"){
      setSuccessMessage("A password reset link has been sent to your email address. Please follow the instructions in the email to reset your password.");
      setModalOpen(false);
      setResetSuccessModalOpen(true);
    }
    else{
      let theErrorMessage = "We are not able to reset your password at this time. Please try again later.";
      if(res && res.includes("user-not-found")){
        theErrorMessage = "We could not find an account associated with this email. Please try a different email.";
      }
      setErrorMessage(theErrorMessage);
    }
  }

return (
    <Grid container className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} md={7} className={classes.image}>
        <div style={{textAlign: "center", position: "relative", top: "50%", color: "#fff"}}>
          <Typography component="h2" variant="h3" style={{color: "#F0E448"}}>AFRICATERER</Typography>
          <Typography variant="caption" style={{color: "#fff"}}>WELCOME!!</Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email"
              onChange={e => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              onChange={e => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Typography style={{ textAlign: 'center' }}>Don't have an account?&nbsp;&nbsp;
            <Link component="button" variant="body2" onClick={() =>window.location.href="/"}>Sign Up</Link>
            </Typography>
          </form>
          <Link component="button" variant="body2" onClick={() =>setModalOpen(true)}>I forgot my password &#9785;</Link>
        </div>
      </Grid>
      <Dialog open={modalOpen} onClose={()=>setModalOpen(false)}>
        <DialogTitle align="center">Reset Your Password</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            fullWidth
            label="Enter your email address"
            onChange={e => setPasswordResetEmail(e.target.value)}
          />
          <Button
              fullWidth
              variant="outlined"
              color="secondary"
              className={classes.submit}
              onClick={handlePasswordReset}
            >
              Reset
            </Button>
            {errorMessage&&<Typography style={{ color: "red" }}>{errorMessage}</Typography>}
        </DialogContent>
      </Dialog>
      <Dialog open={resetSuccessModalOpen} onClose={()=>setResetSuccessModalOpen(false)}>
        <DialogTitle align="center">Reset Link Sent</DialogTitle>
        <DialogContent>
          <Typography style={{ color: "green" }}>{successMessage}</Typography><br />
          <Button
              fullWidth
              variant="outlined"
              color="default"
              onClick={()=>window.location.reload()}
            >
              OK
            </Button>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}