import swal from 'sweetalert';
import { GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail,
    createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification
} from "firebase/auth";
import { auth } from "../firebase";
import { UpdateCaterer } from "../functions/UpdateCaterer";
import SetCookie from "../hooks/SetCookie";

const provider = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

const register = async(cData)=>{
    await UpdateCaterer("POST", cData);
}

const onSuccess = async(result, country = null, serviceType = null)=>{
    let usr = {};
    usr.email = result.user.email;
    usr.token = result.user.accessToken;
    if(result.user.metadata.createdAt === result.user.metadata.lastLoginAt||
        (result.user.metadata.lastLoginAt - result.user.metadata.createdAt)<=10){
            localStorage.setItem("showDialog", "yes");
    }
    let cData = {};
    cData.serviceType = serviceType;
    cData.businessAddress = {};
    if(country!==null)cData.businessAddress.country = country;
    SetCookie("usrin", JSON.stringify(usr));
    await register(cData);
    if(!result.user.emailVerified){
        sendEmailVerification(auth.currentUser);
        swal("Login Failed", "Please verify your email address. See your inbox (or spam) for further instructions. Thank you!!", "info")
        .then(() => {
            window.location.reload(true);
        });
        return;
    }
    swal("Success", "Login Successful", "success", {
        buttons: false,
        timer: 2000,
    });
    localStorage.setItem("user", "groot");
    window.location.href = "/";
}
const onError=(error)=>{
    if(error?.code.includes("email-already-in-use")){
        swal("Oops!!", "An account with this email already exists.", "info", {
            button: "SIGN IN",
        })
        .then(() => {
            window.location.href = "/login";
        });
    }
    else{
        swal("Error", "Invalid Credentials", "error");
    }
}
export const signInWithGoogle = ()=>{
    signInWithPopup(auth, provider).then((result)=>{
        onSuccess(result);
    }).catch((error)=>{
        onError(error);
    });
}

export const signUpWithEmail = (email, password, country, serviceType)=>{
    createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
        onSuccess(userCredential, country, serviceType);
    })
    .catch((error) => {
        onError(error);
    });
}

export const signInWithEmail = (email, password)=>{
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
        onSuccess(userCredential);
    })
    .catch((error) => {
        onError(error);
    });
}

export const resetPassword = async(email)=>{
    let res = "";
    await sendPasswordResetEmail(auth, email)
    .then(() => {
        res = "successful";
    })
    .catch((error) => {
        res = error.code;
    });
    return res;
}