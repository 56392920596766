import * as React from "react";
import {TextField, Button, Dialog, DialogTitle,
    DialogContent, DialogActions, Typography
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { UpdatePopup } from "../functions/UpdatePopup";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";

export default function PopupModal({store={}, open, action}) {
    const [popup, setPopup] = React.useState(store);
    const [newItem, setNewItem] = React.useState({});
    const [extras, setExtras] = React.useState([]);
    const [extra, setExtra] = React.useState({});

    const handleClose = () => {
        window.location.reload();
    };

    const makePopupChange=async()=>{
        if(!popup.title||!popup.date||!popup.acceptUntil||!popup.items||popup.items.length<=0)return;
        let mthd = "POST";
        if(action==="Delete")mthd="DELETE";
        if(action==="Edit")mthd="PATCH";

        await UpdatePopup(mthd, popup);
    }

    const deleteItem=(item)=>{
        const items = popup.items;
        const remItems = items.filter(i=>i !== item);
        setPopup((prev) => {return {...prev, "items": remItems }});
    }

    const updateItem=()=>{
        if(!newItem||!newItem.name||!newItem.price)return;
        newItem.extras = extras;
        const items = popup.items||[];
        items.push(newItem);
        setPopup((prev) => {return {...prev, "items": items }});
        setNewItem({});
        setExtras([]);
    }

    const updateExtra=()=>{
        if(!extra||!extra.item||!extra.additionalPrice)return;
        const additionalItems = extras||[];
        additionalItems.push(extra);
        setExtras(additionalItems);
        setExtra({});
    }

    const smallTextProps = { "margin": "dense", size:"small", disabled: action === "Delete" };
    const textProps = smallTextProps;
    textProps.fullWidth = true;

    return (
    <div>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{action} Popup</DialogTitle>
            <DialogContent>
                <TextField {...textProps} label="Title" defaultValue={popup.title||""} onChange={(e) => setPopup((prev) => {return {...prev, "title": e.target.value }})} />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateTimePicker"]}>
                        <DateTimePicker
                            disabled={action === "Delete"}
                            label="Popup Date"
                            disablePast={true}
                            value={dayjs(popup?.date)||dayjs(new Date().toString())}
                            onChange={(newValue) => setPopup((prev) => {return {...prev, "date": newValue["$d"] }})}
                        />
                    </DemoContainer>
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateTimePicker"]}>
                        <DateTimePicker
                            disabled={action === "Delete"}
                            label="Accept orders until"
                            disablePast={true}
                            maxDate={dayjs(popup?.date)}
                            value={dayjs(popup?.acceptUntil)||dayjs(new Date().toString())}
                            onChange={(newValue) => setPopup((prev) => {return {...prev, "acceptUntil": newValue["$d"] }})}
                        />
                    </DemoContainer>
                </LocalizationProvider>
                <Typography style={{textDecoration: "underline"}}>Items</Typography>
                {popup.items && popup.items.length>0 &&
                <div>
                    {popup.items.map((s)=>(
                        <div key={`${s.name}-${s.details}-${s.price}`}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <TextField {...smallTextProps}
                                    label="Name"
                                    defaultValue={s.name || ""}
                                    disabled />&nbsp;
                                <TextField {...smallTextProps}
                                    label="Details"
                                    defaultValue={s.details || ""}
                                    disabled />&nbsp;
                                <TextField {...smallTextProps}
                                    label="Price"
                                    defaultValue={s.price || 0.00}
                                    disabled />
                                {action !== "Delete" && <DeleteIcon style={{ cursor: "pointer", margin: 7 }} fontSize="large" onClick={() => deleteItem(s)} />}
                            </div>
                            <span style={{fontStyle: "italic", fontSize: 12}}>{s.extras&&s.extras.length>0 &&s.extras.map(extra=>`w/ ${extra.item}: +${extra.additionalPrice}`).join(", ")}</span>
                        </div>
                    ))}
                </div>
                }
                <Typography style={{textAlign: "center", fontWeight: "bold", textDecoration: "underline"}}>Add New</Typography>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <TextField {...smallTextProps}
                        label="Name"
                        value={newItem.name||""}
                        onChange={(e)=>setNewItem((prev) => {return {...prev, "name": e.target.value }})}
                    />&nbsp;
                    <TextField {...smallTextProps}
                        label="Details"
                        multiline
                        maxRows={4}
                        value={newItem.details||""}
                        onChange={(e)=>setNewItem((prev) => {return {...prev, "details": e.target.value }})}
                    />&nbsp;
                    <TextField {...smallTextProps}
                        label="Price"
                        value={newItem.price||""}
                        type="number"
                        onChange={(e)=>setNewItem((prev) => {return {...prev, "price": e.target.value }})}
                    />
                </div>
                <span style={{fontStyle: "italic", fontSize: 12}}>{extras&&extras.length>0 &&extras.map(extra=>`w/ ${extra.item}: +${extra.additionalPrice}`).join(", ")}</span>
                <br />
                <Typography style={{textAlign: "center", fontSize: 12, fontWeight: "bold" }}>Include additional items</Typography>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <TextField {...smallTextProps}
                        label="Item"
                        value={extra.item||""}
                        onChange={(e)=>setExtra((prev) => {return {...prev, "item": e.target.value }})}
                    />&nbsp;
                    <TextField {...smallTextProps}
                        label="Additional Price"
                        value={extra.additionalPrice||""}
                        type="number"
                        onChange={(e)=>setExtra((prev) => {return {...prev, "additionalPrice": e.target.value }})}
                    />
                    {action!=="Delete"&&<AddCircleIcon style={{cursor:"pointer", margin: 7}} fontSize="large" onClick={updateExtra} />}
                </div>
                {action!=="Delete"&&<Button style={{backgroundColor: "maroon", color: "white", width: "100%"}} onClick={updateItem}>Add Item</Button>}
                {/* {action!=="Delete"&&<AddCircleIcon style={{cursor:"pointer", margin: 7}} fontSize="large" onClick={updateItem} />} */}

            </DialogContent>
            <DialogActions>
                <Button style={{backgroundColor: "black", color: "white"}} onClick={handleClose}>Cancel</Button>
                <Button variant="outlined" color={action==="Delete"?"error":"success"} onClick={makePopupChange}>{action==="Delete"?"Delete":"Save"}</Button>
            </DialogActions>
        </Dialog>
    </div>
  );
}
