import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, List, Typography, Divider, CssBaseline } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import HomeIcon from '@mui/icons-material/Home';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import HelpIcon from '@mui/icons-material/Help';
import InventoryIcon from '@mui/icons-material/Inventory';
import Dashboard from './Dashboard';
import Profile from './Profile';
import Settings from './Settings';
import Orders from './Orders';
import Menu from './Menu';
import Products from './Products';
import Nav from '../../shared/Nav';
import { FAQs } from './FAQs';
import CustomDialog from '../../components/CustomDialog';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import { signOut } from '../../auth/SignOut';
import StoreIcon from '@mui/icons-material/Store';
import Popup from './Popup';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { InstallAppOnDevice } from '../../functions/InstallAppOnDevice';

export default function Home() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [headerTxt, setHeaderTxt] = React.useState(localStorage.getItem("HeaderText")||"Dashboard");
    const serviceType = localStorage.getItem("serviceType")||"Catering";
    const mainMenu = ["Dashboard", "Orders", "Account"];
    if(serviceType.toLocaleLowerCase().includes("shop")){
      mainMenu.push("Products");
      mainMenu.push("Settings");
    }
    else{
      mainMenu.push("Settings");
      mainMenu.push("Menu");
      mainMenu.push("Popup");
      mainMenu.push("FAQs");
    }

    const handleClick=(sct)=>{
        if(sct === "Logout"){
            signOut();
        }
        else{
            setHeaderTxt(sct);
        }
    }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const installApp=async()=>{
    await InstallAppOnDevice();
  }

return (
    <><Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <Nav.AppBar position="fixed" open={open}>
      <Toolbar style={{ backgroundColor: '#800000' }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">{headerTxt}</Typography>
      </Toolbar>
    </Nav.AppBar>
    <Nav.Drawer variant="permanent" open={open}>
      <Nav.DrawerHeader>
        <IconButton aria-label="close drawer" onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </Nav.DrawerHeader>
      <Divider />
      <List>
        {mainMenu.map((text) => (
          <ListItemButton onClick={() => handleClick(text)}
            key={text}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              {text === 'Settings' ?
                <SettingsApplicationsIcon style={{ backgroundColor: headerTxt === "Settings" && "#1919FF", color: headerTxt === "Settings" && "#fff" }} /> :
                (text === 'Dashboard' ?
                  <HomeIcon style={{ backgroundColor: headerTxt === "Dashboard" && "#1919FF", color: headerTxt === "Dashboard" && "#fff" }} /> :
                  (text === 'Orders' ?
                    <ShoppingBagIcon style={{ backgroundColor: headerTxt === "Orders" && "#1919FF", color: headerTxt === "Orders" && "#fff" }} /> :
                    (text === 'Menu' ?
                      <RestaurantMenuIcon style={{ backgroundColor: headerTxt === "Menu" && "#1919FF", color: headerTxt === "Menu" && "#fff" }} /> :
                      (text === 'FAQs' ?
                        <HelpIcon style={{ backgroundColor: headerTxt === "FAQs" && "#1919FF", color: headerTxt === "FAQs" && "#fff" }} /> :
                          (text === 'Products' ?
                            <InventoryIcon style={{ backgroundColor: headerTxt === "Products" && "#1919FF", color: headerTxt === "Products" && "#fff" }} /> :
                              (text === "Popup"?
                                <StoreIcon style={{ backgroundColor: headerTxt === "Popup" && "#1919FF", color: headerTxt === "Popup" && "#fff" }} /> :
                                <AccountBoxIcon style={{ backgroundColor: headerTxt === "Account" && "#1919FF", color: headerTxt === "Account" && "#fff" }} />
                              )
                          )
                      )
                    )
                  )
                )
              }
              </ListItemIcon>
            <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        ))}
      </List>
      <Divider />
      <List>
        <ListItemButton onClick={() => handleClick("Logout")}
          key="Logout"
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
        >
          <ListItemIcon sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}><LogoutIcon /></ListItemIcon>
          <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </List>
      <Divider />
      <List id="btn-install-app" style={{display: "none"}}>
        <ListItemButton onClick={installApp}
          key="Install App"
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
        >
          <ListItemIcon sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}><CloudDownloadIcon color="error" /></ListItemIcon>
          <ListItemText primary="Install App" sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </List>
    </Nav.Drawer>
    <Box component="main" className="mainContent" sx={{ flexGrow: 1, p: 3 }}>
      <Nav.DrawerHeader />
      {headerTxt === "Dashboard" ? <Dashboard /> :
        (headerTxt === "Settings" ? <Settings /> :
          (headerTxt === "Orders" ? <Orders /> :
            (headerTxt === "Menu" ? <Menu /> :
              (headerTxt === "FAQs" ? <FAQs /> :
                (headerTxt === "Products" ? <Products /> :
                  (headerTxt === "Popup" ? <Popup /> : <Profile />)
                )
              )
            )
          )
        )
      }
    </Box>
    <CustomDialog />
  </Box>
  <Box style={{ backgroundColor: "#800000", textAlign: "center", color: "#fff", padding: 15 }}>
    <div>
      <a href="https://africaterer.com/policies/Terms.pdf" aria-label="Read the terms and conditions" className="linkText" target="_blank" rel="noreferrer">Terms and Conditions</a>
      <br/>
      <a href="https://africaterer.com/policies/PrivacyPolicy.pdf" aria-label="Read the privacy policy" className="linkText" target="_blank" rel="noreferrer">Privacy Policy</a>
    </div>
    <div style={{ textAlign: "right", position: 'absolute', right: 10, bottom: 20}}>
      <a href="mailto:support@africaterer.com" aria-label="Send an email" className="linkText"><EmailOutlinedIcon /></a> &nbsp;
      <a href="https://www.instagram.com/africaterer" aria-label="Go to instagram" className="linkText"><InstagramIcon /></a>
    </div>
    <br/>
    <div>
      <a href="/" aria-label="See the FAQs" className="linkText" onClick={()=>localStorage.setItem('HeaderText', 'FAQs')}>FAQs</a>
    </div>
    </Box>
    </>
  );
}