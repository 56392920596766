import { IsValid } from './IsValid';

export const UpdateOrder = async(orderData)=>{
  let email = await IsValid();
  fetch(`${process.env.REACT_APP_API_URL}api/vendor/changeOrderStatus?email=${email}`, {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(orderData)
    })
    .then(async response=>{
      if(response.ok){
        window.location.reload(true);
      }
      else{
        return Promise.reject(response.statusText);
      }
    })
    .catch(error=>console.log("Error: ", error));
}