import { IsValid } from "./IsValid";

export const UpdateMenu = async(mtd, fs)=>{
  let email = await IsValid();
  let dmethod = mtd === "add" ? "POST" : (mtd === "edit" ? "PATCH" : "DELETE");
  fetch(`${process.env.REACT_APP_API_URL}api/vendor/menu?email=${email}`, {
    method: dmethod,
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(fs)
  })
  .then(()=>{
    localStorage.removeItem("foodSettings");
    window.location.reload();
  });
  return;
}