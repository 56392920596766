import { AccordionDetails, AccordionSummary, Accordion, Typography, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import config from '../../config';

export const FAQs = ()=>{
    localStorage.setItem("HeaderText", "FAQs");

    return (
        <>
        {config.faqs.map((f, i)=>(
            <Accordion key={i}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#4f2a11" }}>
                    <Typography style={{ color: "#eee" }}>
                        {"Q"}{i+1}{". "}{f.question}
                    </Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                    {f.videoLink&&<div style={{ width: "100%",height: 250}}>
                        <iframe title="menu" allow="autoplay;" allowFullScreen style={{ border: "none"}} src={f.videoLink} width={"100%"} height={"100%"}></iframe>
                    </div>}
                    {f.answer&&f.answer.map((a,i)=>(
                    <div key={i}><Typography variant="caption">{a}</Typography><br /></div>
                    ))}
                </AccordionDetails>
            </Accordion>
        ))}
        <Typography style={{fontSize:16, color:"#800000", textAlign:"center"}}>For all other questions or concerns, please contact us at support@africaterer.com</Typography>
        </>
    )
}