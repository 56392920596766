import React from 'react';
import { Grid, FormControl, Select, Checkbox, FormGroup, MenuItem, DialogContent, DialogContentText,
  TextField, Button, Divider, FormControlLabel, InputLabel, Dialog, DialogTitle, InputAdornment, Link, Typography
} from '@mui/material';
import Item from '../../shared/Item';
import { useEffect, useState } from 'react';
import { Avatar } from '@material-ui/core';
import { GetCaterer } from '../../functions/GetCaterer';
import { UpdateCaterer } from '../../functions/UpdateCaterer';
import { saveFileToStorage } from '../../functions/AddToStorage';
import statesconfig from '../../statesconfig.js';
import SearchAddress from '../../functions/SearchAddress';
import config from '../../config';
// import { deleteCaterer } from '../../auth/SignOut';

const Profile=()=> {
  const [catererData, setCatererData] = useState({
    active: false
  });
  const [updateCaterer, setUpdateCaterer] = useState(false);
  const [catererAddy, setCatererAddy] = useState({});
  const [addressChanged, setAddressChanged] = useState(false);
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [profileError, setProfileError] = useState("");
  const [addressesToConfirm, setAddressesToConfirm] = useState([]);
  const [phoneNumberConfig, setPhoneNumberConfig] = useState({});
  const [displayAccount, setDisplayAccount] = useState("profile");
  const accountDivs = ["profile"]; //subscription, serviceType, close account

  localStorage.setItem("HeaderText", "Profile");

  useEffect(() =>{
    const getInfo=async()=>{
      const response = await GetCaterer();
      if(response.status === 200){
        let data = await response.json();
        setCatererAddy(data.businessaddress||{});
        setCatererData(data);
        setUpdateCaterer(true);
        let theOperatingCountry = config.OperatingCountries.filter(o=>o.name===data.businessaddress?.country);
        if(theOperatingCountry.length>0){
          let phoneConfig = {};
          phoneConfig.length = theOperatingCountry[0].phone?.length;
          phoneConfig.areaCode = theOperatingCountry[0].phone?.areaCode;
          phoneConfig.format = theOperatingCountry[0].phone?.format;
          setPhoneNumberConfig(phoneConfig);
        }
      }
    }
    getInfo();
  }, []);

  const isValidPhone=()=>{
    let isValid = false;
    const phoneregex = /\d{10}/i;
    if (phoneregex.test(catererData.phone)){
      isValid = true;
    }
    return isValid;
  }

  const handleCatererDataChange=(e)=>{
    let d = e.target.labels[0].outerText;
    d = d.replaceAll(" ", "").toLowerCase();
    let val = e.target.checked?e.target.checked:(e.target.type==="checkbox"?false:e.target.value);
    setCatererData((prev) => {return {...prev, [d]: val}});
  }

  const handleAddressChange=(e)=>{
    setAddressChanged(true);
    let {value, name } = e.target;
    setCatererAddy((prev) => {return {...prev, [name]: value}});
  }

  const handleConfirmAddress=async(addy)=>{
    let caddy = catererAddy;
    let coords = {};
    coords.latitude = addy.latitude;
    coords.longitude = addy.longitude;
    caddy.coordinates = coords;
    setCatererAddy(caddy);
    await finishCatererUpdate();
  }

  const confirmAddress=async()=>{
    setProfileError("");
    let errorMsg;
    if(!catererAddy.state)errorMsg = "Invalid State";
    if(catererAddy.country==="USA" && !catererAddy.zip)errorMsg = "Invalid Postal Code";
    if(errorMsg){
      setProfileError(errorMsg);
      return;
    }
    let catererAddress = `${catererAddy?.street} ${catererAddy?.apt||""} ${catererAddy?.city}, ${catererAddy?.state}, ${catererAddy?.zip} ${catererAddy?.country}`
    let addys = await SearchAddress(catererAddress);
    if(addys!==null && addys.length>0){
      let otherAddy = {};
      otherAddy.formattedAddress = catererAddress;
      otherAddy.latitude = addys[0].latitude;
      otherAddy.longitude = addys[0].longitude;
      addys.push(otherAddy);

      setAddressesToConfirm(addys);
      setOpenAddressDialog(true);
      return;
    }
    await finishCatererUpdate();
  }

  const finishCatererUpdate=async()=>{
    if(!isValidPhone()){
      setProfileError("Invalid Phone Number");
      return;
    };
    setProfileError("");
    let cData = catererData;
    cData.areaCode = phoneNumberConfig.areaCode;
    cData.businessaddress = catererAddy;
    let businessLnk = await cleanName(catererAddy);
    cData.businesslink = businessLnk;
    setCatererData(cData);
    let dmethod ="PATCH";
    if(!updateCaterer){
      dmethod="POST";
    }
    UpdateCaterer(dmethod, catererData);
  }

  const handleCatererUpdate=async()=>{
    if(!addressChanged)return await finishCatererUpdate();
    await confirmAddress();
  }

  const handleLogoChange=async(e)=>{
    e.preventDefault();
    let selectedFile = e.target.files[0];
    saveFileToStorage(selectedFile, catererData, "caterer");
  }

  const cleanName = async() => {
    if(!catererData.businessname||!catererData.businessaddress)return "";
    let lnk = catererData.businessname;
    const rgx = /[^a-zA-Z0-9]/ig;
    lnk = lnk.replaceAll(rgx, "");
    lnk =`/${lnk.toLowerCase()}`;
    return lnk;
  }

  const setDisplay=(acct)=>{
    setDisplayAccount(acct);
  }

  return (
    <>
    <h2 style={{ textAlign: 'center' }}>Hello, {catererData.businessname||""}</h2>
    <Divider/>
    <Grid container spacing={2} direction="row">
    <Grid item xs={12} md={3}>
      <Item>
        <div style={{  display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Avatar
            alt={catererData.businessname||"LOGO"}
            src={catererData.imageUrl}
            sx={{ width: 150, height: 150 }}
          /><br /><br /><br />
        </div>
        <Button variant="contained" component="label" color="success">Change Image
          <input hidden accept="image/*" type="file" onChange={handleLogoChange} />
        </Button><br />
        <span style={{ fontSize: 11}}>This can be your logo or a picture of the food that represents your business.</span>
      </Item>
      <br />
      <Divider />
      <div style={{textAlign: "center"}}>
        {accountDivs.map(a=>(
          <Typography key={a}>
            <Link href="#" underline="hover" variant="body2" style={{ fontSize: 21, color: (displayAccount===a?"black":"grey"), opacity: (displayAccount!==a&&0.5) }} onClick={()=>setDisplay(a)}>{a.toUpperCase()}</Link>
          </Typography>
        ))}
      </div>
      <Divider color="success" />
    </Grid>
    <Grid item xs={12} md={8}>
      {displayAccount==="profile"&&<Item>
        {profileError&&<span style={{color: "red"}}>{profileError}</span>}
        <FormGroup>
          <TextField label="Phone" value={catererData.phone||""} helperText={phoneNumberConfig.length + " digit phone number e.g " + phoneNumberConfig.format} inputProps={{ maxLength: phoneNumberConfig.length }} InputProps={{
            startAdornment: <InputAdornment position="start">{phoneNumberConfig.areaCode}</InputAdornment>,
          }} variant="standard" onChange={handleCatererDataChange} /><br/>
          <TextField label="Business Name" value={catererData.businessname||""} variant="standard" onChange={handleCatererDataChange} /><br/>
          <TextField label="Street Name" name="street" variant="standard" value={catererAddy.street||""} onChange={handleAddressChange} /><br/>
          <TextField label="Apt/Suite/House No" name="apt" variant="standard" value={catererAddy.apt||""} onChange={handleAddressChange}/><br/>
          <TextField label="City" variant="standard" name="city" value={catererAddy.city||""} onChange={handleAddressChange}/><br/>
          <FormControl size="small">
            <InputLabel>State</InputLabel>
            <Select
              value={catererAddy.state||""}
              label="State"
              name="state"
              onChange={handleAddressChange}
            >
              {statesconfig[`${catererAddy.country||"USA"}`].map((c, i)=>(
                <MenuItem key={i} value={c.name}>{c.abbreviation||c.name}</MenuItem>
              ))}
            </Select>
          </FormControl><br/>
          <TextField label="Postal Code" variant="standard" name="zip" value={catererAddy.zip||""} onChange={handleAddressChange}/><br/>
          {localStorage.getItem("serviceType")&&localStorage.getItem("serviceType").toLocaleLowerCase().includes("catering")&&<FormControlLabel control={<Checkbox onChange={handleCatererDataChange} checked={catererData.active||false} />} label="Active" />}<br/><br/>
          <Button variant="outlined" color="success" onClick={() => handleCatererUpdate()}>Update</Button>
        </FormGroup>
      </Item>}
      {/* {displayAccount.includes("subscription")&&<Item>
        <Typography>Subscription Plan: {catererData?.subscription?.plan}</Typography>
        <div style={{textAlign: "left"}}>
          <ul>
            <li>Create and manage unlimited food item listings.</li>
            <li>Access to order management tools for efficient processing.</li>
            <li>Basic insights into sales and order performance.</li>
            <li>24/7 customer support.</li>
          </ul>
        </div>
        <div style={{border: "1px solid", borderRadius: 5, padding: 5}}>
          <Typography style={{textAlign: "right"}}><span style={{fontWeight: "bolder"}}>{catererData?.subscription?.rateType}: </span> {catererData?.subscription?.rate}</Typography>
          <Typography><span style={{fontWeight: "bolder"}}>Plan Start Date:</span> {new Date(catererData?.subscription?.startDate).toDateString()}</Typography>
          <Typography style={{textAlign: "right"}}><span style={{fontWeight: "bolder"}}>Commission: </span> 10%</Typography> // DELETE
          <Typography><span style={{fontWeight: "bolder"}}>Plan Start Date:</span> {new Date().toDateString()}</Typography> // DELETE
        </div>
      </Item>} */}
      </Grid>
    </Grid>
    <Dialog onClose={()=>setOpenAddressDialog(false)} open={openAddressDialog}>
                <DialogTitle textAlign="center" color="success">Confirm Address</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {addressesToConfirm.length>0 && addressesToConfirm.map((c,i)=>(
                            <span key={i}><br />
                                <span>{c.formattedAddress}</span><br />
                                <Button variant="contained" color="error" onClick={() => handleConfirmAddress(c)}>Confirm</Button>
                            </span>
                        ))}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
    {/* <br /><Divider /><br />
    <Button color="secondary" variant="outlined" fullWidth onClick={()=>deleteCaterer(catererData)}>DELETE MY ACCOUNT</Button> */}
    </> 
  );
}
export default Profile;