import React from 'react';
import CustomPieChart from './CustomPieChart';

export default function BestSellerPieChart({orders}) {
  const [statusGroups, setStatusGroups] = React.useState([]);

  React.useEffect(() => {
    let orderItems = [];
    orders.forEach((order) => {
      order?.orderItems?.forEach(orderItem=>{
        let i = orderItems.findIndex(o=>o.name === orderItem.food||orderItem.name);
        if(i !== -1){
          orderItems[i].value +=orderItem.quantity;
        }
        else{
          let newItem = {};
          newItem.name = orderItem.food||orderItem.name;
          newItem.value = orderItem.quantity;
          orderItems.push(newItem);
        }
      });
    });
    setStatusGroups(orderItems.sort((b, a)=>a.value-b.value).slice(0,5));
  }, [orders]);

  return (
      <CustomPieChart groups={statusGroups} chartTitle={"Best Sellers"}/>
    );
}