import * as React from 'react';
import { Typography, Box, Button } from '@mui/material';
import Signup from '../Signup';

export default function Main() {

    return (
        <div>
            <div style={{ padding: 5}} className="divMenu">
                <ul>
                    <li><Button style={{color: "maroon"}} name="login" onClick={()=>window.location.href="/login"}>LOGIN</Button></li>
                </ul>
            </div>
            <Box sx={{ flexDirection: { xs: "column", md: "row" }, display: {xs:"grid", md: "flex"}, gridTemplateRows: "350px 1fr", padding: 1 }}>
                <div className="bg">
                    <div style={{textAlign: "center", position: "relative", top: "50%"}}>
                        <Typography component="h2" variant="h3" color="#F0E448">AFRICATERER</Typography>
                        <Typography variant="caption" color="#FFF">JOIN US!!</Typography>
                    </div>
                    <br />
                </div>
                <div><Signup /></div>
            </Box>
        </div>
    );
}