import React from "react";
import Item from '../shared/Item';
import { TextField, FormGroup, FormControlLabel, Checkbox, Button, Stack, Divider } from "@mui/material";
import { UpdateSetting } from "../functions/UpdateSettings";

const OrderSetting=({val, general})=>{
    const [orderTypes, setOrderTypes] = React.useState(val||[]);
    const [genSettings, setGenSettings] = React.useState(general||[]);

    const handleOrderTypeChange=(e)=>{
        const {value, checked} = e.target;
        let oTypes = [...orderTypes];
        if(checked){
            setOrderTypes([...orderTypes, value]);
        }
        else{
            let i = oTypes.findIndex(a=>a === value);
            if(i !== -1)oTypes.splice(i, 1);
            setOrderTypes(oTypes);
        }
    }

    const setMaxMiles=(e)=>{
        let gSettings = [...genSettings];
        const {name, value} = e.target;
        let st = {};
        st.name = name;
        st.value = value;
        let i = gSettings.findIndex(a=>a.name === name);
        if(i !== -1)gSettings.splice(i, 1);
        gSettings.push(st);
        setGenSettings(gSettings);
    }

    const saveOrderType=()=>{
        let setting = {};
        setting.settingName = "orderType";
        setting.settingValue = orderTypes;
        UpdateSetting("add", setting);

        let gsetting = {};
        gsetting.settingName = "general";
        gsetting.settingValue = genSettings;
        UpdateSetting("add", gsetting);
    }

    return (
        <>
        <Stack>
            <Item>Set the accepted order type and maximum delivery distance<br/><br/>
            <Divider color="error"/><Divider color="default"/><br/>
                <FormGroup>
                    <FormControlLabel value="pickup" control={<Checkbox />} onChange={handleOrderTypeChange} label="Pickup" checked={orderTypes.includes("pickup")} />
                    <FormControlLabel value="delivery" control={<Checkbox />} onChange={handleOrderTypeChange} label="Delivery" checked={orderTypes.includes("delivery")}/>
                    <TextField variant="standard" defaultValue={genSettings.filter(g=>g.name==="maxMiles")[0]?.value} label="Maximum distance you're willing to travel for deliveries (in miles)" type="number" name="maxMiles" onChange={setMaxMiles} />
                </FormGroup>
                <br/>
                <Button variant="outlined" color="secondary" onClick={saveOrderType}>Save</Button>
            </Item>
        </Stack>
        </>
    )
}

export default OrderSetting;