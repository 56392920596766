import React from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mui/material';

let instr = [
    {
        "title": "Welcome!!!",
        "message": "Create your MENU, PROFILE and SETTINGS to get started. Visit the FAQs page for instructions on setting up."
    }
]
const CustomDialog=()=> {
    const [ind, setInd] = React.useState(0);
    const [open, setOpen] = React.useState(localStorage.getItem("showDialog")?true:false);


    const handleClose = ()=>{
        localStorage.removeItem("showDialog")
        setOpen(false);
    }

    const goToNext = ()=>{
        let nxt = ind+1;
        if(nxt>=instr.length)nxt=0;
        setInd(nxt);
    }
    const goToPrevious = ()=>{
        let nxt = ind-1;
        if(nxt<0)nxt=0;
        setInd(nxt);
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle color="secondary">{instr[ind].title}</DialogTitle>
            <DialogContent>{instr[ind].message}</DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                {ind>0&&<Button onClick={goToPrevious}>Previous</Button>}
                {instr.length-1>ind&&<Button onClick={goToNext}>Next</Button>}
            </DialogActions>
        </Dialog>
    );
}

export default CustomDialog;