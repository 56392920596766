import React from "react";
import Item from "../shared/Item";
import { TextField, FormGroup, FormControlLabel, 
    RadioGroup, Radio, Checkbox,
    Button, Stack, Divider, Typography
} from "@mui/material";
import { UpdateSetting } from "../functions/UpdateSettings";

const ShippingSetting=({val})=>{
    const [shippingSetting, setShippingSetting] = React.useState(val||{type: "flat", rate: 10});
    const [freeShipping, setFreeShipping] = React.useState(shippingSetting.freeShipping||{});

    const saveShippingSetting=()=>{
        let setting = {};
        shippingSetting.freeShipping = {...freeShipping};
        setting.settingName = "shipping";
        setting.settingValue = shippingSetting;
        UpdateSetting("add", setting);
    }

    const handleFreeShippingCheck = (e)=>{
        const {checked, value} = e.target;
        if(checked){
            setFreeShipping((prev) => {return {...prev, [value]: 25 }})
        }
        else{
            let newObj = { ...freeShipping };
            delete newObj[value];
            setFreeShipping(newObj);  
        }
    }

    return (
        <>
        <Stack>
            <Item>Set Shipping Fees<br/><br/>
            <Divider color="error"/><Divider color="default"/><br/>
                <Typography align="left" style={{textDecoration: "underline"}}>Paid Shipping</Typography> 
                <FormGroup>
                    <RadioGroup value={shippingSetting.type} onChange={(e)=>setShippingSetting((prev) => {return {...prev, "type": e.target.value }})}>
                        <FormControlLabel value="flat" control={<Radio />} label="Flat Rate" />
                        <FormControlLabel value="percent" control={<Radio />} label="Percentage" />
                    </RadioGroup>
                    <TextField variant="standard" defaultValue={shippingSetting.rate||""} label="rate" helperText={"(e.g 10: to represent 10% of order total or $10)"} onChange={(e)=>setShippingSetting((prev) => {return {...prev, "rate": e.target.value }})} />
                </FormGroup><br />
                <Typography align="left" style={{textDecoration: "underline"}}>Offer Conditional Free Shipping?</Typography>
                <FormGroup>
                    {/* <FormControlLabel value="localDelivery" onChange={handleFreeShippingCheck} control={<Checkbox />} label="Local Delivery" /> */}
                    {/* {freeShipping?.localDelivery&&<TextField helperText="free delivery to addresses within the specified distance" variant="standard" defaultValue={freeShipping?.localDelivery||""} label={"Max miles"} onChange={(e)=>setFreeShipping((prev) => {return {...prev, "localDelivery": e.target.value }})} />} */}
                    <FormControlLabel value="minimumOrder" onChange={handleFreeShippingCheck} control={<Checkbox checked={freeShipping.hasOwnProperty('minimumOrder')||false} />} label="Minimum Order" />
                    {freeShipping?.minimumOrder&&<TextField helperText="free shipping when the order total is more than the specified amount" variant="standard" defaultValue={freeShipping?.minimumOrder} label={"Minimum Amount"} onChange={(e)=>setFreeShipping((prev) => {return {...prev, "minimumOrder": e.target.value }})} />}
                </FormGroup>
                <br/>
                <Button variant="outlined" color="secondary" onClick={saveShippingSetting}>Save</Button>
            </Item>
        </Stack>
        </>
    )
}

export default ShippingSetting;