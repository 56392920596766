import { IsValid } from './IsValid';

export const UpdateSetting = async(mtd, setting)=>{
  let email = await IsValid();
  let dmethod = mtd === "add" ? "POST" : "GET";
  await fetch(`${process.env.REACT_APP_API_URL}api/vendor/setting?email=${email}`, {
    method: dmethod,
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(setting)
  })
  .then(()=>{
    window.location.reload();
  });
  return;
}