import React from "react";
import PaymentSetting from "../../components/PaymentSetting";
import AvailabilitySetting from "../../components/AvailabilitySetting";
import OrderSetting from "../../components/OrderSetting";
import { Box, Tab, Tabs } from '@mui/material';
import GeneralSetting from "../../components/GeneralSetting";
import { GetSettings } from "../../functions/GetSettings";
import ServingSetting from "../../components/ServingSetting";
import ShippingSetting from "../../components/ShippingSetting";

export default function Settings() {
  localStorage.setItem("HeaderText", "Settings");
  const [allSettings, setAllSettings] = React.useState({
    orderType: [],
    paymentType: [],
    workDays: [],
    holidays: [],
    servings: [],
    general: [],
    businessAddress: {}
  });

  const [isLoading, setIsLoading] = React.useState(true);
  const [value, setValue] = React.useState("general");
  const serviceType = localStorage.getItem("serviceType");
  let allTabs = [
    {label: "General", value: "general"},
    {label: "Order", value: "order"},
    {label: "Servings", value: "servings"},
    {label: "Payment", value: "payment"},
    {label: "Availability", value: "availability"},
  ];

  if(serviceType && serviceType.toLocaleLowerCase().includes("shop")){
    allTabs = allTabs.filter(t=>t.value==="payment");
    allTabs.push({label: "Shipping", value: "shipping"})
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getSetting=()=>{
    switch (value.toLowerCase()){
      case "payment":
        return <PaymentSetting val={allSettings.paymentType} addy={allSettings.businessAddress} serviceType={serviceType}/>
      case "availability":
        return <AvailabilitySetting workDays={allSettings.workDays} holidays={allSettings.holidays}/>
      case "order":
          return <OrderSetting val={allSettings.orderType} general={allSettings.general}/>
      case "servings":
        return <ServingSetting val={allSettings.servings}/>
      case "shipping":
        return <ShippingSetting val={allSettings.shipping} />
      default:
          return <GeneralSetting val={allSettings.general}/>;
    }
  }

  React.useEffect(()=>{
    const getSettings = async()=>{
      setAllSettings(await GetSettings());
      setIsLoading(false);
    }
    getSettings();
  }, []);

  if(isLoading){
    return (<></>);
  }

  return (
    <><Box sx={{ bgcolor: 'background.paper' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {allTabs.map(a=>(
          <Tab key={a.value} label={a.label} value={a.value} />
        ))}
      </Tabs>
    </Box>
    <br/>
    <Box>
      {(getSetting())}
    </Box></>
  );
}