import { ClearCache } from './ClearCache';
import { getToken } from '../auth/GetToken.js';

export const IsValid = async()=>{
  let token = await getToken();
  if(!token || token===null)ClearCache();
  const res = await fetch(`${process.env.REACT_APP_API_URL}api/auth/validate`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': token.token
    }
  })
  .then(data=>data);
  if(!res.ok)ClearCache();
  return token.email;
}