import { IsValid } from './IsValid';

export const GetCaterer = async()=>{
  let email = await IsValid();
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/vendor?email=${email}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  });
  return response;
}