import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    storageBucket: `${process.env.REACT_APP_PROJECT_ID}.appspot.com`,
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: `${process.env.REACT_APP_PROJECT_ID}.firebaseapp.com`,
    projectId: `${process.env.REACT_APP_PROJECT_ID}`,
    // appId: `${process.env.REACT_APP_APP_ID}`,
    // messagingSenderId: `${process.env.REACT_APP_MESSAGE_SENDER_ID}`,
};
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
// export const messaging = getMessaging(app);