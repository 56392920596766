import React from "react";
import { FormGroup, Typography, Button, Box,
  TextField, Stack, TableCell, TableRow, TableBody, Table, TableContainer, TableHead, Paper, Select, MenuItem, InputLabel
} from "@mui/material";
import Item from "../shared/Item";
import Modal from '@mui/material/Modal';
import {UpdateMenu} from '../functions/UpdateMenu';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: "70%",
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 5,
};

const MenuSettingsModal=({modalOpen, method, food})=>{
  const [pageNumber, setPageNumber] = React.useState(0);
  const [foodServings, setFoodServings] = React.useState([]);
  const [substitute, setSubstitute] = React.useState({item:"", addPrice:0.00});
  const [selectedServing, setSelectedServing] = React.useState("");

  React.useEffect(()=>{
    if(food.servings){
      setFoodServings(food.servings);
      setSelectedServing(food.servings[0].serving);
    }
  }, [food.servings]);

  const closeModal=()=>{
    window.location.reload();
  }

  const setFoodServingData=(serving, price)=>{
    const servings = foodServings.filter(s=>s.serving !== serving);
    servings.push({serving:serving, price:parseFloat(price).toFixed(2)});
    setFoodServings(servings);
  }

  const addFoodSubstitutes=()=>{
    if(!substitute.item)return;
    if(!substitute.addPrice)setSubstitute((prev) => {return {...prev, "addPrice": 0.00}});
    const servings = foodServings.filter(s=>s.serving !== selectedServing);
    const matchingServings = foodServings.filter(s=>s.serving === selectedServing);
    if(matchingServings.length>0){
      const servingToChange = matchingServings[0];
      // get substitutes
      const subs = servingToChange.substitutes||[];
      const filteredSub = subs.filter(s=>s.item !== substitute.item);
      filteredSub.push(substitute);
      // remove from servings
      servingToChange.substitutes = filteredSub;
      servings.push(servingToChange);
    }
    setFoodServings(servings);
    setSubstitute({item:"", addPrice:0.00});
  }

  const getDefaultServingPrice=(serving)=>{
    const servingType = foodServings.filter(s=>s.serving===serving);
    if(servingType.length>0)return parseFloat(servingType[0].price);
    return 0.00;
  }

  const ServingsPage=()=>{    
    return <Box>
      <Typography>Set the price for each serving type available for this food</Typography>
      {food?.availableServings?.map((s, i)=>(
        <Box key={i}>
          <TextField margin="dense" fullWidth label={s.serving} type="number" variant="outlined" size="small" 
          defaultValue={getDefaultServingPrice(s.serving)} onChange={(e)=>setFoodServingData(s.serving, e.target.value)} /><br/>
        </Box>
      ))}
    </Box>
  }

  const removeSubstitute=(sub)=>{
    const servings = foodServings.filter(s=>s.serving!==selectedServing);
    const sctServing = foodServings.filter(s=>s.serving===selectedServing)[0];
    const filteredSub = sctServing.substitutes.filter(s=>s.item!==sub.item);
    sctServing.substitutes = filteredSub;
    servings.push(sctServing);
    setFoodServings(servings);
  }

  const subsTable=()=>{
    const matchingServing = foodServings.filter(s=>s.serving===selectedServing)[0]||{};
    const rows = matchingServing.substitutes;
    if(!rows||rows.length<=0)return;
    return <Box>
      <Typography>Substitutes for <span style={{color: "maroon", fontWeight: "bolder"}}>{selectedServing}</span> serving</Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Substitute</TableCell>
              <TableCell>Additional Price</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i}>
                <TableCell>{row.item}</TableCell>
                <TableCell>{row.addPrice}</TableCell>
                <TableCell align="right"><DeleteIcon fontSize="large" style={{cursor:"pointer"}} onClick={()=>removeSubstitute(row)} /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  }

  const SubstitutePage=()=>{
    return <Box>
      <Typography style={{textAlign:"center"}}>EXTRAS AND SUBSTITUTES</Typography>
      <Typography variant="caption">What can your customers add to their order when they select this item? (e.g meat - goat, fish, beef, chicken). Add all the options for each serving.</Typography>
      <FormGroup>
        <InputLabel>Select Serving</InputLabel>
        <Select
          value={selectedServing||""}
          label="serving"
          name="serving"
          onChange={(e)=>setSelectedServing(e.target.value)}>
            {foodServings.map(s=>(
            <MenuItem key={s.serving} value={s.serving}>{s.serving}</MenuItem>
            ))}
        </Select>
      </FormGroup>
      <Stack>
        <Item>
          <TextField variant="outlined" value={substitute.item} margin="dense" label={"substitute"} size="small"
            onChange={(e)=>setSubstitute((prev) => {return {...prev, "item": e.target.value}})} />
          <TextField variant="outlined" value={substitute.addPrice} margin="dense" label={"additional price"} size="small" type="number" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            onChange={(e)=>setSubstitute((prev) => {return {...prev, "addPrice": parseFloat(e.target.value)}})} />
          {selectedServing&&<AddCircleIcon style={{marginTop: 9, cursor:"pointer"}} fontSize="large" onClick={addFoodSubstitutes} />}
        </Item>
      </Stack><br />
      {subsTable()}
    </Box>
  }

  const goToNextPage=()=>{
    let pageSize = pageNumber;
    if(foodServings.length>0)pageSize++;
    setPageNumber(pageSize);
  }

  const displayMenuSetting=()=>{
    switch (pageNumber) {
      case 1:
        return SubstitutePage();
      default:
        return ServingsPage();
    }
  }

  const saveMenu=async()=>{
    const menu=food;
    const validServings = foodServings.filter(s=>s.price>0);
    menu.servings = validServings;
    if(food.available===undefined)menu.available = true;
    await UpdateMenu(method, menu);
  }

  return (
    <Modal
      open={modalOpen}
      onClose={()=>closeModal()}
    >
      <Box sx={style}>
        <Typography style={{ fontWeight: 'bolder', textAlign: 'center' }}>
            {food.group} - {food.food}
        </Typography>
        <FormGroup>
          <Box sx={{width: "100%"}}>{displayMenuSetting()}</Box>
          {pageNumber===1?
          <Button variant="outlined" color="success" onClick={saveMenu}>SAVE <SaveIcon fontSize="large" /></Button>:
          <Button variant="outlined" color="error" style={{color: "black"}} onClick={goToNextPage}>NEXT<NavigateNextIcon fontSize="large" /></Button>}
        </FormGroup>
      </Box>
    </Modal>
  )
}
export default MenuSettingsModal;