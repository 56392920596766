import React, { useState } from 'react';
import { 
  Button, Paper, Grid, Typography, Link, TextField,
  FormControl, Select, MenuItem, InputLabel
} from '@material-ui/core';
import useStyles from '../hooks/UseStyles.js';
import { signUpWithEmail } from '../auth/SignIn.js';
import config from '../config.js';

export default function Signup() {
  const classes = useStyles();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmpassword, setConfirmPassword] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [country, setCountry] = useState("USA");
  const [serviceType, setServiceType] = useState("Catering");

  const handleSignUp = e => {
    e.preventDefault();
    if(confirmpassword !== password){
      setErrorMessage("Passwords do not match!!");
      return;
    }
    signUpWithEmail(email, password, country, serviceType);
  }

  return (
    <Grid container className={classes.root}>
      <Grid item component={Paper} elevation={6} square>
        <div className={classes.paper}>
          {/* <Typography component="h1" variant="h6">0% Commission for 30 days </Typography> */}
          <form className={classes.form} onSubmit={handleSignUp}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="email"
              label="Email"
              onChange={e => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              onChange={e => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmpassword"
              label="Confirm Password"
              type="password"
              onChange={e => setConfirmPassword(e.target.value)}
            />
            <FormControl size="small" fullWidth>
              <InputLabel>Country</InputLabel>
              <Select
              value={country||""}
              label="Country"
              name="country"
              onChange={e=>setCountry(e.target.value)}
              >
                {config.OperatingCountries.map((c, i)=>(
                <MenuItem key={i} value={c.name}>{c.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <FormControl size="small" fullWidth>
              <InputLabel>Service Type</InputLabel>
              <Select
              value={serviceType||""}
              label="ServiceType"
              name="serviceType"
              onChange={e=>setServiceType(e.target.value)}
              >
                {config.serviceTypes.map((c, i)=>(
                <MenuItem key={i} value={c}>{c}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <Button style={{ backgroundColor: "#800000", color: "#eee"}}
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >Sign Up</Button>
            <Typography variant="caption">* By creating an account, you agree to our <a href="https://africaterer.com/policies/Terms.pdf" target="_blank" rel="noreferrer">Terms Of Use</a> and <a href="https://africaterer.com/policies/PrivacyPolicy.pdf" target="_blank" rel="noreferrer">Privacy Policy</a></Typography>
          </form>
          <p>Already have an account? <Link component="button" variant="body2" onClick={()=>window.location.href="/login"}>Sign In</Link></p>
          {errorMessage&&<Typography color="error">{errorMessage}</Typography>}
        </div>
      </Grid>
    </Grid>
  );
}