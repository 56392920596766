import * as React from "react";
import {TextField, Button, Dialog, DialogTitle,
    DialogContent, DialogActions,
    FormGroup, Typography, Avatar, Badge,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { saveFileToStorage } from "../functions/AddToStorage";
import { UpdateProduct } from "../functions/UpdateProduct";
import { v4 as uuidv4 } from "uuid";
import { deleteFileFromStorage } from "../functions/DeleteFromStorage";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function ProductModal({prod={}, open, action}) {
    const [product, setProduct] = React.useState(prod);
    const [newSize, setNewSize] = React.useState({});
    const [newTag, setNewTag] = React.useState("");
    const [imagesToDelete, setImagesToDelete] = React.useState(action==="Delete"?product.imageUrls||[]:[]);
    const numberPattern = /^[0-9]+(\.[0-9]+)?$/;
    const [errorMessage, setErrorMessage] = React.useState("");

    const handleClose = () => {
        window.location.reload();
    };

    const makeProductChange=async()=>{
        setErrorMessage("");
        let daProduct = {...product};
        if(action!=="Delete"){
            if(!numberPattern.test(product.available)){
                console.log("i get ere");
                daProduct.available = 1;
                // setProduct((prev) => {return {...prev, "available": 1 }});
            }
            if(!product||!product.name||!product.sizes||product.sizes.length<=0){
                setErrorMessage("Missing required items. Please verify your product includes the name and sizes.");
                return;
            }
        }
        let mthd = "POST";
        imagesToDelete.forEach(imageUrl => {
            deleteFileFromStorage(imageUrl.url);
        });
        if(action==="Delete")mthd="DELETE";
        if(action==="Edit")mthd="PATCH";

        await UpdateProduct(mthd, daProduct);
    }

    const updateProductSize=()=>{
        if(!newSize||!newSize.size||!newSize.price||newSize.size.trim().length<=0||!numberPattern.test(newSize.price)){
            setNewSize({size:"", price:""});
            // setErrorMessage("Invalid size or price. Please check your entry.");
            return;
        }
        const selectedProduct = product;
        const allSizes = selectedProduct.sizes?.filter(s=>s.size.trim().toLowerCase()!==newSize.size.trim().toLowerCase())||[];
        const validSize = newSize;
        validSize.price = parseFloat(newSize.price.trim()).toFixed(2);
        allSizes.push(validSize);
        setProduct((prev) => {return {...prev, "sizes": allSizes }});
        setNewSize({size:"", price:""});
    }

    const deleteProductSize=(sizeToDelete)=>{
        const selectedProduct = product;
        const remSizes = selectedProduct.sizes.filter(s=>s.size!==sizeToDelete);
        setProduct((prev) => {return {...prev, "sizes": remSizes }});
    }

    const updateProductTags=()=>{
        if(!newTag||newTag.trim().length<=0){
            setNewTag("");
            return;
        }
        const selectedProduct = product;
        const allTags = selectedProduct.tags?.filter(t=>t.trim().toLowerCase()!==newTag.trim().toLowerCase())||[];
        allTags.push(newTag);
        setProduct((prev) => {return {...prev, "tags": allTags }});
        setNewTag("");
    }

    const deleteProductTag=(tag)=>{
        const selectedProduct = product;
        const remTags = selectedProduct.tags.filter(t=>t!==tag);
        setProduct((prev) => {return {...prev, "tags": remTags }});
    }

    const deleteProductImage=(imageId)=>{
        const selectedProduct = product;
        // delete from storage
        const imageToDelete = selectedProduct.imageUrls.filter(t=>t.id===imageId)||[];
        if(imageToDelete.length > 0){
            const imgToDelete = imagesToDelete;
            imgToDelete.push(imageToDelete[0]);
            setImagesToDelete(imgToDelete);
        }
        // update product
        const remImages = selectedProduct.imageUrls.filter(t=>t.id!==imageId);
        setProduct((prev) => {return {...prev, "imageUrls": remImages }});
    }

    const updateProductImages=async(file, id)=>{
        const selectedProduct = product;
        const matchingImage = selectedProduct.imageUrls?.filter(i=>i.id===id)||[];
        let imageUUID = uuidv4();
        if(matchingImage.length>0){
            // we're replacing this image so delete it from storage
            const imgToDelete = imagesToDelete;
            imgToDelete.push(matchingImage[0]);
            setImagesToDelete(imgToDelete);
        }
        const imageDetails={_id:`${imageUUID}(${id})`};
        // todo: save to storage only on save: if they decide to cancel, we have an orphan image
        const imageUrl = await saveFileToStorage(file, imageDetails, "product");
        const newImage={id:id, url:imageUrl};
        const allImages = selectedProduct.imageUrls?.filter(i=>i.id!==id)||[];
        allImages.push(newImage);
        setProduct((prev) => {return {...prev, "imageUrls": allImages }});
    }

    const smallTextProps = { "margin": "dense", size:"small", disabled: action === "Delete" };
    const textProps = smallTextProps;
    textProps.fullWidth = true;
    const imageButtonProps = {size: "small", component: "label", variant: "outlined", startIcon: <CloudUploadIcon /> };
    const imageProps = {sx: { width: "100%", height: 66 }, variant: "square"};
    const disabledProps ={disabled: action === "Delete"};

    return (
    <div>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{action} Product</DialogTitle>
            <DialogContent>
                <TextField {...textProps}
                    label = "Product Name"
                    defaultValue={product?.name||""}
                    onChange={(e)=>setProduct((prev) => {return {...prev, "name": e.target.value }})}
                />
                <TextField {...textProps}
                    label = "Product Description"
                    defaultValue={product?.description||""}
                    multiline
                    maxRows={4}
                    onChange={(e)=>setProduct((prev) => {return {...prev, "description": e.target.value }})}
                />
                <FormGroup>
                    <TextField {...smallTextProps}
                        label="Quantity In Stock"
                        type="number"
                        defaultValue={product.available||1}
                        onChange={(e)=>setProduct((prev) => {return {...prev, "available": e.target.value }})}
                    />
                </FormGroup>
                <Typography style={{textDecoration: "underline"}}>SIZES</Typography>
                {product.sizes && product.sizes.length>0 &&
                <div>
                    {product.sizes.map((s)=>(
                        <div key={s.size} style={{display: "flex", justifyContent: "space-between"}}>
                            <TextField {...smallTextProps}
                                label="Size"
                                defaultValue={s.size||""}
                                disabled
                            />&nbsp;
                            <TextField {...smallTextProps}
                                label="Price"
                                defaultValue={s.price||0.00}
                                disabled
                            />
                            {action!=="Delete"&&<DeleteIcon style={{cursor:"pointer", margin: 7}} fontSize="large" onClick={()=>deleteProductSize(s.size)} />}
                        </div>
                    ))}
                </div>
                }
                <Typography style={{textAlign: "center", fontWeight: "bold", textDecoration: "underline"}}>Add New Size</Typography>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <TextField {...smallTextProps}
                        label="Size"
                        value={newSize.size||""}
                        onChange={(e)=>setNewSize((prev) => {return {...prev, "size": e.target.value }})}
                    />&nbsp;
                    <TextField {...smallTextProps}
                        label="Price"
                        type="number"
                        value={newSize.price||""}
                        onChange={(e)=>setNewSize((prev) => {return {...prev, "price": e.target.value }})}
                    />
                    {action!=="Delete"&&<AddCircleIcon style={{cursor:"pointer", margin: 7}} fontSize="large" onClick={updateProductSize} />}
                </div>
                <br />
                <Typography style={{textDecoration: "underline"}}>TAGS</Typography>
                <Typography variant="caption">Help customers find you easily!! Tags will help match your product with relevant searches.</Typography>
                {product.tags && product.tags.length>0 &&
                <div>
                    {product.tags.map((t)=>(
                        <div key={t} style={{display: "flex", justifyContent: "space-between"}}>
                            <TextField {...smallTextProps}
                                label="Tag"
                                defaultValue={t||""}
                                disabled
                            />
                            {action!=="Delete"&&<DeleteIcon style={{cursor:"pointer", margin: 7}} fontSize="large" onClick={()=>deleteProductTag(t)} />}
                        </div>
                    ))}
                </div>
                }
                <Typography style={{textAlign: "center", fontWeight: "bold", textDecoration: "underline"}}>Add New Tag</Typography>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <TextField {...smallTextProps}
                        label="Tag"
                        value={newTag||""}
                        onChange={(e)=>setNewTag(e.target.value)}
                    />
                    {action!=="Delete"&&<AddCircleIcon style={{cursor:"pointer", margin: 7}} fontSize="large" onClick={updateProductTags} />}
                </div>
                {errorMessage&&<Typography style={{fontSize: 10, color: "red"}}>{errorMessage}</Typography>}
                <br />
                <Typography style={{textDecoration: "underline"}}>PICTURES</Typography>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                        <div>
                            <Badge style={{width: "100%",}} badgeContent={
                                action!=="Delete"&&<DeleteIcon style={{cursor:"pointer"}} color="error" onClick={()=>deleteProductImage(1)} />
                            }>
                                <Avatar
                                    alt="1st food image"
                                    src={product.imageUrls?.filter(i=>i.id===1)[0]?.url}
                                    {...imageProps}
                                />
                            </Badge>
                        </div>
                        <Button {...imageButtonProps} {...disabledProps} color="error">
                            Upload Image 1<VisuallyHiddenInput type="file" onChange={(e)=>updateProductImages(e.target.files[0], 1)} />
                        </Button>
                    </div>&nbsp;
                    <div>
                        <div>
                            <Badge style={{width: "100%",}} badgeContent={
                                action!=="Delete"&&<DeleteIcon style={{cursor:"pointer"}} color="success" onClick={()=>deleteProductImage(2)} />
                            }>
                                <Avatar
                                    alt="2nd food image"
                                    src={product.imageUrls?.filter(i=>i.id===2)[0]?.url}
                                    {...imageProps}
                                />
                            </Badge>
                        </div>
                        <Button {...imageButtonProps} {...disabledProps} color="success">
                            Upload  Image 2<VisuallyHiddenInput type="file" onChange={(e)=>updateProductImages(e.target.files[0], 2)} />
                        </Button>
                    </div>&nbsp;
                    <div>
                        <div>
                            <Badge style={{width: "100%",}} badgeContent={
                                action!=="Delete"&&<DeleteIcon style={{cursor:"pointer"}} color="primary" onClick={()=>deleteProductImage(3)} />
                            }>
                                <Avatar
                                    alt="3rd food image"
                                    src={product.imageUrls?.filter(i=>i.id===3)[0]?.url}
                                    {...imageProps}
                                />
                            </Badge>
                        </div>
                        <Button {...imageButtonProps} {...disabledProps}>
                            Upload  Image 3<VisuallyHiddenInput type="file" onChange={(e)=>updateProductImages(e.target.files[0], 3)} />
                        </Button>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button style={{backgroundColor: "black", color: "white"}} onClick={handleClose}>Cancel</Button>
                <Button variant="outlined" color={action==="Delete"?"error":"success"} onClick={makeProductChange}>{action==="Delete"?"Delete":"Save"}</Button>
            </DialogActions>
        </Dialog>
    </div>
  );
}
