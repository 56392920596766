import { Box, Button, Grid, FormGroup, TextField,
  Typography, Divider, Stack,
} from "@mui/material";
import React from "react";
import Item from "../../shared/Item";
import config from "../../config";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GetPopups } from "../../functions/GetPopups";
import PopupModal from "../../components/PopupModal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { UpdatePopupSettings } from "../../functions/UpdatePopupSettings";

const popupLimit = 2;
export default function Popup() {
  localStorage.setItem("HeaderText", "Popup");
  const [isLoading, setIsLoading] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [currency, setCurrency] = React.useState("$");
  const [popups, setPopups] = React.useState([]);
  const [selected, setSelected] = React.useState({});
  const [action, setAction] = React.useState("Add");
  const [settings, setSettings] = React.useState({subscribeList:[]});
  const [subscriber, setSubscriber] = React.useState("");

  React.useEffect(()=>{
    const getData=async()=>{
      setIsLoading(true);
      // get popups and settings
      const res = await GetPopups();
      if(res.popups)setPopups(res.popups);
      if(res.settings)setSettings(res.settings);
      // get currency
      const bizAddy = localStorage.getItem("businessAddress");
      if(bizAddy){
        const country = config.OperatingCountries.filter(o=>o.name===(JSON.parse(bizAddy)?.country));
        if(country[0]){
          setCurrency(country[0]?.currencySign);
        }
      }
      setIsLoading(false);
    }
    getData();
  }, []);

  if(isLoading){
    return (<div>Loading...</div>)
  }

  const openModal=()=>setModalOpen(true);

  const getSelectedPopup=(id)=>{
    const selectedPopup = popups.filter(popup=>popup._id === id);
    setSelected(selectedPopup[0]);
  }

  const editPopup=(id)=>{
    getSelectedPopup(id);
    setAction("Edit");
    openModal();
  }

  const deletePopup=(id)=>{
    getSelectedPopup(id);
    setAction("Delete");
    openModal();
  }

  const saveSettings=async()=>{
    await UpdatePopupSettings(settings);
  }

  if(modalOpen){
    return <PopupModal open={modalOpen} store={selected} action={action}/>
  }

  const addToSubscribers=()=>{
    if(!subscriber)return;
    const subscribers = settings.subscribeList||[];
    subscribers.push(subscriber);
    setSettings((prev) => {return {...prev, "subscribeList":subscribers}});
    setSubscriber("");
  }

  const removeSubscriber=(subscriber)=>{
    const subscribers = settings.subscribeList;
    const remSubscribers = subscribers.filter(s=>s!==subscriber);
    setSettings((prev) => {return {...prev, "subscribeList":remSubscribers}});
  }

  return (
    <Box>
      {popups.length<popupLimit&&<Button style={{float: "right"}} variant="outlined" color="success" onClick={openModal}>ADD NEW</Button>}
      <br />
      {popups.length>0?
        <div>
          <Grid container spacing={2}>
          {popups.map(popup =>(
            <Grid item key={popup._id} md={6}>
              <Item style={{textAlign: "left"}}>
                <Typography style={{textAlign:"center", fontWeight: "bolder"}}><a target="_blank"rel="noreferrer" href={process.env.REACT_APP_CUSTOMER_URL + "popup/" + popup._id}>{popup.title.toUpperCase()}</a></Typography>
                <div className="product-list">
                  <Typography><span>Date: </span>{new Date(popup.date).toLocaleString("en-US", { dateStyle: "full", timeStyle: "short" })}</Typography>
                  <span>Items: </span>{popup.items?.map((item, i) =>(
                    <div key={i}>
                      <Typography><span>{`(${i+1})`} </span>{item.name}</Typography>
                      {item.details&&<Typography style={{fontStyle:"italic"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.details}</Typography>}
                      <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Price: </span>{currency}{item.price}</Typography>
                      {item.extras&&item.extras.length>0&&<Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Extras: </span>{item.extras.map(extra=>`${extra.item} (+${currency}${extra.additionalPrice})`).join(", ")}</Typography>}
                    </div>
                  ))}
                  <Typography><span>Accept orders until: </span>{new Date(popup.acceptUntil).toLocaleString("en-US", { dateStyle: "full", timeStyle: "short" })}</Typography>
                </div>
                <Divider style={{backgroundColor: "black", height: 2}} />
                <div style={{display: "flex", justifyContent: "right"}}>
                  <div>
                    <EditIcon color="success" style={{cursor:"pointer"}} onClick={()=>editPopup(popup._id)} />
                    <DeleteIcon color="error" style={{cursor:"pointer"}} onClick={()=>deletePopup(popup._id)} />
                  </div>
                </div>
              </Item>
            </Grid>
          ))}
          </Grid>
        </div>
        :
        <div style={{padding: 40, textAlign: "center"}}>
          <Typography variant="h2">You have no popups.</Typography>
        </div>
      }
      <br />
      <Divider style={{backgroundColor: "brown", height: 2}} />
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2 }} justifyContent="space-around">
        <Item>
        <Typography style={{textDecoration: "underline"}}>Subscribers</Typography>
        <Typography variant="caption">* Your subscribers will get a message about your popup each time you add or update one.</Typography>
        <FormGroup>
          {settings&&settings.subscribeList?.length>0&&settings?.subscribeList.map(s=>(
            <div key={s} style={{display: "flex", justifyContent: "space-between"}}>
              <TextField margin="dense" defaultValue={s} disabled size="small" fullWidth />
              <DeleteIcon style={{cursor:"pointer", margin: 7}} fontSize="large" onClick={()=>removeSubscriber(s)} />
            </div>
          ))}
          <br /><Divider style={{height:2, backgroundColor: "green"}} /><br />
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <TextField fullWidth label="Phone number" onChange={(e)=>setSubscriber(e.target.value)} helperText="Add new subscriber" size="small" />
            <AddCircleIcon style={{cursor:"pointer", margin: 7}} fontSize="large" onClick={addToSubscribers} />
          </div>
        </FormGroup>
      </Item>
      </Stack>
      <br />
      <Button fullWidth variant="outlined" color="secondary" onClick={saveSettings}>Save Changes</Button>
      <br />
    </Box>
  );
}