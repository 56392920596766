import React from "react";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import Stack from '@mui/material/Stack';
import config from "../../config";
import CustomMenu from '../../components/CustomMenu';
import Item from '../../shared/Item';
import { GetMenu } from "../../functions/GetMenu";
import { GetCurrency } from "../../functions/GetCurrency";
import { Select, MenuItem, TextField, Button } from "@mui/material";
import { GetSettings } from "../../functions/GetSettings";
import MenuSettingsModal from "../../components/MenuSettingsModal";

export default function Menu() {

  localStorage.setItem("HeaderText", "Menu");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [menu, setMenu] = React.useState([]);
  const [currency, setCurrency] = React.useState("");
  const [food, setFood] = React.useState({group:"Appetizer"});
  const [servings, setServings] = React.useState([]);

  React.useEffect(() =>{
    const onLoad = async()=>{
      setMenu(await GetMenu());
      const settings = await GetSettings();
      setServings(settings.servings);
      setCurrency(GetCurrency());
    }
    onLoad();
  }, []);

  const menuSettings=()=>{
    setFood((prev) => {return {...prev, "availableServings": servings}});
    setModalOpen(true);
  }

  return (
    <>
    <MenuSettingsModal modalOpen={modalOpen} food={food} method="add"/>
    <h2 style={{ textAlign: "center"}}>Set Your Menu</h2>
    <Divider/><br/>
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2 }}
      justifyContent="space-around">
      <Item>
        <div>
          <span style={{ fontSize: 10 }}>Create Menu</span>
          <FormGroup>
            <Select
              value={food.group||""}
              label="Category"
              name="category"
              onChange={(e)=>setFood((prev) => {return {...prev, "group": e.target.value}})}
            >
              {config.Categories.map((c, i)=>(
                <MenuItem key={i} value={c}>{c}</MenuItem>
              ))}
            </Select>
            <TextField label="Name" value={food.food||""} variant="standard" onChange={(e)=>setFood((prev) => {return {...prev, "food": e.target.value}})} /><br/>
            <TextField label="Description" value={food.description||""} variant="standard" onChange={(e)=>setFood((prev) => {return {...prev, "description": e.target.value}})} /><br/>
            {!servings||servings.length<=0?
            <div>
              Please add your serving types in settings before creating your menu
              {/* <Button variant="outlined" color="secondary" onClick={goToServingSetting}>Go TO SETTINGS</Button> */}
            </div>:
            <div>
              {food.food&&<Button variant="outlined" color="secondary" onClick={menuSettings}>Add to Menu</Button>}
            </div>
            }
          </FormGroup>
        </div>
      </Item>
      <Item>
        <span style={{ fontSize: 16, color:"#000034", textTransform: 'uppercase' }}>Menu</span><br/>
        <CustomMenu menu={menu} currency={currency} availableServings={servings}/>
      </Item>
    </Stack>
    </>
  );
}