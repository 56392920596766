export const InstallAppOnDevice=async()=>{
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
        // The deferred prompt isn't available.
        return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Reset the deferred prompt variable, since prompt() can only be called once.
    window.deferredPrompt = null;
    // Hide the install button.
    document.getElementById("btn-install-app").style.display = "none";
}