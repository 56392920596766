import { IsValid } from './IsValid';

export const UpdateCaterer = async(mtd, catererData)=>{
  let email = await IsValid();
  catererData.email = email;
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/vendor?email=${email}`, {
    method: mtd,
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(catererData)
  });
  if(response.status===201){
    const isJson = response.headers.get('content-type')?.includes('application/json');
    const data = isJson ? await response.json() : null;
    if(data.serviceType)localStorage.setItem("serviceType", JSON.stringify(data.serviceType));
    if(data.businessAddress)localStorage.setItem("businessAddress", JSON.stringify(data.businessAddress));
    console.log("OK");
  }
  else if(response.ok){
    window.location.reload(true);
  }
  else{
    console.log(response.statusText);
  }
}