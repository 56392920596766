import { IsValid } from "./IsValid";

export const GetOrders = async()=>{
    let email = await IsValid();
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/vendor/orders?email=${email}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(data => data);
    if(response.ok){
        let result = await response.json();
        let orders = [];
        if(!result[0]){
            return orders;
        }
        result.forEach(o=>{
            let newOrder = o.order;
            newOrder.orderItems = o.orderDetails;
            newOrder.total = o.orderTotal;
            orders.push(newOrder);
        });
        return orders;
    };
    return [];
}